import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { ThemeProvider } from "@emotion/react";
import { DashboardPage } from "pages/DashboardPage";
import HostnamePage from "pages/HostnamePage";
import IndexPage from "pages/IndexPage";
import MembershipPage from "pages/MembershipPage";
import { UserEventsPage } from "pages/UserEventsPage";
import { useMemo, useState } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { theme } from "theme";

function App() {
  const [token, setToken] = useState(localStorage.getItem("token") || "");

  const client = useMemo(() => {
    const errorLink = onError(({ operation, graphQLErrors }) => {
      if (graphQLErrors?.[0]?.extensions?.code === "invalid-jwt") {
        localStorage.removeItem("token");
        window.location.reload();
        return;
      }
    });

    const httpLink = new HttpLink({
      uri: "https://oopy.lazyrockets.com/v1/graphql",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return new ApolloClient({
      cache: new InMemoryCache(),
      link: errorLink.concat(httpLink),
    });
  }, [token]);

  return token ? (
    <ThemeProvider theme={theme}>
      <ApolloProvider client={client}>
        <Router>
          <Link style={{ margin: "0 8px" }} to="/">
            Users
          </Link>
          <Link style={{ margin: "0 8px" }} to="/dashboard">
            Dashboard
          </Link>
          <Link style={{ margin: "0 8px" }} to="/events">
            Events
          </Link>
          <Link style={{ margin: "0 8px" }} to="/hostnames">
            Hostnames
          </Link>
          <Link style={{ margin: "0 8px" }} to="/membership">
            Membership
          </Link>
          <Switch>
            <Route exact path="/" component={IndexPage} />
            <Route exact path="/dashboard" component={DashboardPage} />
            <Route exact path="/events" component={UserEventsPage} />
            <Route exact path="/hostnames" component={HostnamePage} />
            <Route exact path="/membership" component={MembershipPage} />
          </Switch>
        </Router>
      </ApolloProvider>
    </ThemeProvider>
  ) : (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        const token = ((e.target as HTMLFormElement)[0] as HTMLInputElement)
          .value;
        setToken(token);
        localStorage.setItem("token", token);
      }}
    >
      <input />
      <button type="submit">Enter</button>
    </form>
  );
}

export default App;
