import styled from "@emotion/styled";
import { Enum_Event_Type_Enum } from "graphql/generated";

const Container = styled.div``;

const Email = styled.div`
  font-size: 28px;
  font-weight: bold;
`;

const Hostname = styled.a``;

const EventTable = styled.table``;

export const UserEventsCard = ({
  user,
}: {
  user: UserWithEventsQuery["user"][0];
}) => {
  return (
    <Container>
      <Email>{user.email}</Email>
      {user.hostnames.map((hostname) => (
        <Hostname
          key={hostname.name}
          href={`https://${hostname.name}`}
          target="_blank"
        >
          {hostname.name}
        </Hostname>
      ))}
      <EventTable>
        {user.events
          .filter((e) => e.type !== Enum_Event_Type_Enum.Querystring)
          .map((event) => (
            <tr>
              <td
                style={{
                  color: [
                    Enum_Event_Type_Enum.BillingKey,
                    Enum_Event_Type_Enum.Hostname,
                    Enum_Event_Type_Enum.Signin,
                  ].includes(event.type)
                    ? "red"
                    : undefined,
                }}
              >
                {event.type}
              </td>
              <td>{event.op}</td>
              <td>{new Date(event.createdAt + "Z").toLocaleString()}</td>
              <td style={{ width: 300, overflow: "hidden" }}>
                {JSON.stringify(event.data)}
              </td>
            </tr>
          ))}
      </EventTable>
    </Container>
  );
};
