import styled from "@emotion/styled";
import { HTMLAttributes } from "react";

const Container = styled.table`
  font-size: 14px;

  tr {
    height: 36px;

    &:nth-child(2n) {
      background-color: #eee;
    }

    &:hover {
      background-color: #eee;
    }
  }

  td {
    padding: 0 8px;
    text-align: center;
  }
`;

export const Table = (props: HTMLAttributes<HTMLTableElement>) => {
  return <Container {...props} />;
};
