import dayjs from "dayjs";
import {
  Enum_Payment_Pg_Enum,
  NextPayBillComponent,
  useMembershipDataQuery,
} from "graphql/generated";
import _, { orderBy } from "lodash";
import { OopyButton, OopyTable, OopyCheckbox } from "oopy-design-system";
import { useMemo, useState } from "react";

const ConditionalButton = ({ children }: any) => {
  const [show, setShow] = useState(false);

  if (!show) {
    return <OopyButton onClick={() => setShow(true)}>Query</OopyButton>;
  }

  return children;
};

const MembershipPage = () => {
  const { data: originData } = useMembershipDataQuery();

  const [orderByNextPayTime, setOrderByNextPayTime] = useState(false);

  const data = useMemo(() => {
    if (orderByNextPayTime) {
      return orderBy(originData?.hostname ?? [], (h) => h.user.nextPayTime);
    }

    return originData?.hostname ?? [];
  }, [originData, orderByNextPayTime]);

  return (
    <>
      <h1>{data?.length}개</h1>
      <OopyCheckbox
        checked={orderByNextPayTime}
        onClick={() => setOrderByNextPayTime(!orderByNextPayTime)}
        label={"nextPayTime으로 정렬"}
      />
      <OopyTable>
        <thead>
          <tr>
            <th>도메인</th>
            <th>생성날짜</th>
            <th>이메일</th>
            <th>카드등록</th>
            <th>nextPayTime</th>
            <th>멤버수</th>
            <th>결제금액</th>
            <th>무료 payment</th>
            <th>다음결제요금조회</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((item) => (
            <tr key={item.id}>
              <td>
                <a
                  href={`https://${item.name}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {item.name}
                </a>
                <br />
                <span style={{ fontSize: 10 }}>{item.id}</span>
              </td>
              <td>
                {dayjs(new Date(item.createdAt)).format("YYYY-MM-DD")}
                <span style={{ color: "#999999" }}>
                  {" "}
                  (
                  {dayjs(new Date(item.createdAt + "Z")).diff(new Date(), "d") +
                    30}
                  d)
                </span>
              </td>
              <td>
                {item.user.email}
                <br />
                <span style={{ fontSize: 10 }}>{item.user.id}</span>
              </td>
              <td>{item.user.user_view?.hasBillingKey ? "O" : "-"}</td>
              <td>{item.user.nextPayTime}</td>
              <td>{item.members_aggregate.aggregate?.count}</td>
              <td>
                {_.sum(
                  item.membershipProducts
                    .map((product) =>
                      product.membershipProductPrices.map(
                        (price) => price.memberPayments
                      )
                    )
                    .flat()
                    .flat()
                    .map((payment) => payment.amountReceived)
                ) /
                  (item.membershipConfiguration?.pg ===
                  Enum_Payment_Pg_Enum.Stripe
                    ? 100
                    : 1) +
                  (item.membershipConfiguration?.pg ===
                  Enum_Payment_Pg_Enum.Stripe
                    ? "USD"
                    : "KRW")}
              </td>
              <td>
                {item.user.payments
                  .filter((p) => p.price === 0 && p.membershipPoolSize === 1)
                  .map((p) => (
                    <>
                      <span>id: {p.id}</span>
                      <br />
                      <span>createdAt: {p.createdAt}</span>
                      <br />
                      <span>expiresAt: {p.expiresAt}</span>
                      <br />
                    </>
                  ))}
              </td>
              <td>
                <ConditionalButton>
                  <NextPayBillComponent
                    variables={{
                      userId: item.user.id,
                    }}
                  >
                    {({ data }) => (
                      <span>
                        {data?.nextPayBill.nextPayTotal}
                        {data?.nextPayBill.currency}{" "}
                        <span style={{ color: "#999999" }}>
                          (-{data?.nextPayBill.discount}
                          {data?.nextPayBill.currency})
                        </span>
                      </span>
                    )}
                  </NextPayBillComponent>
                </ConditionalButton>
              </td>
            </tr>
          ))}
        </tbody>
      </OopyTable>
    </>
  );
};

export default MembershipPage;
