const light_colors = {
  background: "rgb(255,255,255)",
  textColor: "rgb(55,53,47)",
  textLightColor: "rgb(255,255,255)",
  textDarkColor: "rgb(0,0,0)",
  textSubDarkColor: "rgb(66,66,66)",
  textSubSubDarkColor: "rgb(128, 128, 128)",
  placeholderColor: "rgb(160, 160, 160)",

  buttonHoverColor: "rgb(226,226,226)",
  mainColor: "#6196FF",
  secondaryColor: "rgb(57,58,90)",
  errorColor: "#a11818",
  correctColor: "#228B22",
};

export const colors = {
  ...light_colors,
};
