import styled from "@emotion/styled";
import { PagePicker } from "components/PagePicker";
import { useHostnameQuery } from "graphql/generated";
import { usePagination } from "hooks/usePagination";
import { useState } from "react";

const Container = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
`;

const Scroll = styled.div`
  width: 200px;
  height: 100vh;
  overflow: auto;
`;

const Iframe = styled.iframe`
  flex: 1;
  height: 100%;
`;

const HostnamePage = () => {
  const { page, offset, limit, setPage } = usePagination(100);
  const { data } = useHostnameQuery({
    variables: {
      where: {
        configuration: {
          headScript: {
            _is_null: false,
          },
        },
      },
      limit,
      offset,
    },
  });
  const [src, setSrc] = useState("");

  return (
    <Container>
      <Scroll>
        {data?.hostname.map(({ name }) => (
          <div
            style={{
              margin: "4px 8px",
              color: src === `https://${name}` ? "red" : undefined,
            }}
            onClick={() => setSrc(`https://${name}`)}
          >{`https://${name}`}</div>
        ))}
        <PagePicker
          style={{ alignSelf: "center" }}
          value={page}
          windowSize={10}
          maxWindowValue={Infinity}
          onChange={setPage}
        />
      </Scroll>
      <Iframe src={src} />
    </Container>
  );
};

export default HostnamePage;
