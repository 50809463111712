import styled from "@emotion/styled";
import _ from "lodash";
import { useCallback, useMemo } from "react";

const Container = styled.div``;

const Button = styled.button`
  border: none;
  outline: none;
  width: 36px;
  height: 36px;
  border-radius: 4px;
  background-color: #ffffff;
  color: ${({ theme }) => theme.colors.mainColor};
  border: 1px solid ${({ theme }) => theme.colors.mainColor};
  margin: 0 2px;
  cursor: pointer;

  &:hover,
  &.focused {
    background-color: ${({ theme }) => theme.colors.mainColor};
    color: #ffffff;
    font-weight: bold;
  }
`;

export const PagePicker = ({
  value,
  windowSize = 10,
  maxWindowValue,
  onChange,
  ...props
}: {
  value: number;
  windowSize?: number;
  maxWindowValue: number;
  onChange: (value: number) => void;
  style?: React.CSSProperties;
}) => {
  const range = useMemo(() => {
    const start = value - (value % windowSize);
    const end = Math.min(start + windowSize, maxWindowValue);
    return _.range(start, end);
  }, [value, windowSize, maxWindowValue]);

  const formatValue = useCallback((value) => value + 1, []);

  return (
    <Container {...props}>
      <Button onClick={() => onChange(Math.max(range[0] - 1, 0))}>
        <span className="iconify" data-icon="akar-icons:chevron-left" />
      </Button>
      {range.map(($value) => (
        <Button
          className={$value === value ? "focused" : ""}
          onClick={() => onChange($value)}
        >
          {formatValue($value)}
        </Button>
      ))}
      <Button
        onClick={() =>
          onChange(Math.min(range[range.length - 1] + 1, maxWindowValue))
        }
      >
        <span className="iconify" data-icon="akar-icons:chevron-right" />
      </Button>
    </Container>
  );
};
