import { useMemo, useState } from "react";

export const usePagination = (limit = 10) => {
  const [page, setPage] = useState(0);
  const offset = useMemo(() => page * limit, [page, limit]);

  return {
    page,
    offset,
    limit,
    setPage,
  };
};
