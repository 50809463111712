import { gql } from '@apollo/client';
import * as React from 'react';
import * as Apollo from '@apollo/client';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  bigint: any;
  date: any;
  float8: any;
  jsonb: any;
  numeric: any;
  timestamp: any;
  timestamptz: any;
  uuid: any;
};

export type ApplyGowidPromotionInput = {
  email: Scalars['String'];
  poolSize?: Maybe<Scalars['Int']>;
};

export type ApplyGowidPromotionOutput = {
  __typename?: 'ApplyGowidPromotionOutput';
  createdAt: Scalars['String'];
  email: Scalars['String'];
  expiresAt: Scalars['String'];
  poolSize: Scalars['Int'];
};

export type BillingInfoInput = {
  userId: Scalars['String'];
};

export type BillingInfoOutput = {
  __typename?: 'BillingInfoOutput';
  cardName?: Maybe<Scalars['String']>;
  cardNumber?: Maybe<Scalars['String']>;
  vatIncluded?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: Maybe<Scalars['Boolean']>;
  _gt?: Maybe<Scalars['Boolean']>;
  _gte?: Maybe<Scalars['Boolean']>;
  _in?: Maybe<Array<Scalars['Boolean']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Boolean']>;
  _lte?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Scalars['Boolean']>;
  _nin?: Maybe<Array<Scalars['Boolean']>>;
};

export type CanConnectDomainInput = {
  userId: Scalars['String'];
};

export type CanConnectDomainOutput = {
  __typename?: 'CanConnectDomainOutput';
  canConnect: Scalars['Boolean'];
  hostnameType: Scalars['String'];
  userId: Scalars['String'];
};

export type ChangeEmailAddressInput = {
  newEmail: Scalars['String'];
  userId: Scalars['ID'];
};

export type ChangeEmailAddressOutput = {
  __typename?: 'ChangeEmailAddressOutput';
  status: Scalars['ID'];
};

export type ChangeOopyToCustomInput = {
  id: Scalars['ID'];
  newHostname: Scalars['String'];
};

export type ChangeOopyToCustomOutput = {
  __typename?: 'ChangeOopyToCustomOutput';
  id: Scalars['ID'];
  status: Scalars['String'];
};

export type CheckMemberEmailInput = {
  email: Scalars['String'];
  hostnameId: Scalars['String'];
};

export type CheckMemberEmailOutput = {
  __typename?: 'CheckMemberEmailOutput';
  hasPassword: Scalars['Boolean'];
  isMember: Scalars['Boolean'];
};

export type ClearHostnameDataCacheInput = {
  hostname: Scalars['String'];
};

export type ClearHostnameDataCacheOutput = {
  __typename?: 'ClearHostnameDataCacheOutput';
  hostname: Scalars['String'];
};

export type ClearPageCacheInput = {
  hostname: Scalars['String'];
  pageId?: Maybe<Scalars['String']>;
};

export type ClearPageCacheOutput = {
  __typename?: 'ClearPageCacheOutput';
  hostname: Scalars['String'];
  pageId?: Maybe<Scalars['String']>;
};

export type ConnectDomainInput = {
  hostname: Scalars['String'];
  notionUrl?: Maybe<Scalars['String']>;
  type: HostnameType;
};

export type ConnectDomainOutput = {
  __typename?: 'ConnectDomainOutput';
  hostname: Scalars['String'];
  id: Scalars['ID'];
  notionUrl: Scalars['String'];
  status: HostnameStatusEnum;
};

export type CreateManualMemberInput = {
  email: Scalars['String'];
  expiresAt?: Maybe<Scalars['String']>;
  hostnameId: Scalars['ID'];
  manualPermissionProductId?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  validFrom?: Maybe<Scalars['String']>;
};

export type CreateManualMemberOutput = {
  __typename?: 'CreateManualMemberOutput';
  errors: Array<FormError>;
  memberId?: Maybe<Scalars['String']>;
};

export type CreateManualPermissionInput = {
  expiresAt?: Maybe<Scalars['String']>;
  memberId: Scalars['ID'];
  productId: Scalars['ID'];
  validFrom?: Maybe<Scalars['String']>;
};

export type CreateManualPermissionOutput = {
  __typename?: 'CreateManualPermissionOutput';
  id: Scalars['ID'];
};

export type CreateMembershipHostnameInput = {
  freePayment?: Maybe<Scalars['Boolean']>;
  hostname: Scalars['String'];
  notionUrl: Scalars['String'];
  pg?: MembershipPaymentPg;
  serviceName: Scalars['String'];
  userId: Scalars['ID'];
};

export type CreateMembershipHostnameOutput = {
  __typename?: 'CreateMembershipHostnameOutput';
  id: Scalars['ID'];
};

export type CreateMembershipProductInput = {
  childProductId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  hostnameId: Scalars['String'];
  name: Scalars['String'];
  permission: Scalars['String'];
};

export type CreateMembershipProductOutput = {
  __typename?: 'CreateMembershipProductOutput';
  id: Scalars['ID'];
};

export type CreateMembershipProductPriceInput = {
  currency: MembershipPaymentCurrency;
  description?: Maybe<Scalars['String']>;
  membershipProductId: Scalars['ID'];
  name: Scalars['String'];
  recurringInfo: RecurringInfoInput;
  trialPeriodDays?: Maybe<Scalars['Int']>;
  type: MembershipPaymentType;
  unitAmount: Scalars['Int'];
};

export type CreateMembershipProductPriceOutput = {
  __typename?: 'CreateMembershipProductPriceOutput';
  id: Scalars['ID'];
};

export type CreateMembershipTestUserInput = {
  email: Scalars['String'];
  lang?: Maybe<Scalars['String']>;
};

export type CreateMembershipTestUserOutput = {
  __typename?: 'CreateMembershipTestUserOutput';
  email: Scalars['String'];
  id: Scalars['ID'];
  memo: Scalars['String'];
};

export type CreateRedirectionInput = {
  hostnameId: Scalars['ID'];
  redirectHostname: Scalars['String'];
};

export type CreateRedirectionOutput = {
  __typename?: 'CreateRedirectionOutput';
  hostnameId: Scalars['ID'];
  redirectHostnameId: Scalars['ID'];
};

export type CreateSetupIntentInput = {
  isTest?: Maybe<Scalars['Boolean']>;
  userId: Scalars['ID'];
};

export type CreateSetupIntentOutput = {
  __typename?: 'CreateSetupIntentOutput';
  clientSecret: Scalars['String'];
  userId: Scalars['ID'];
};

export type CreateSlugInput = {
  hostnameId: Scalars['ID'];
  pageId: Scalars['String'];
  slug: Scalars['String'];
};

export type CreateSlugOutput = {
  __typename?: 'CreateSlugOutput';
  createdAt: Scalars['String'];
  id: Scalars['ID'];
  pageId: Scalars['String'];
  slug: Scalars['String'];
};

export enum Currency {
  Krw = 'KRW',
  Usd = 'USD'
}

export type DeleteBillingKeyInput = {
  userId: Scalars['String'];
};

export type DeleteBillingKeyOutput = {
  __typename?: 'DeleteBillingKeyOutput';
  id: Scalars['String'];
};

export type DeleteHostnameInput = {
  hostnameId: Scalars['ID'];
  reason: Scalars['String'];
};

export type DeleteHostnameOutput = {
  __typename?: 'DeleteHostnameOutput';
  id: Scalars['ID'];
};

export type DeleteMemberInput = {
  memberId: Scalars['ID'];
};

export type DeleteMemberOutput = {
  __typename?: 'DeleteMemberOutput';
  id: Scalars['ID'];
};

export type DeleteMembershipProductInput = {
  id: Scalars['ID'];
};

export type DeleteMembershipProductOutput = {
  __typename?: 'DeleteMembershipProductOutput';
  id: Scalars['String'];
};

export type DomainConnectivityInput = {
  hostname: Scalars['String'];
  isRedirectHostname?: Maybe<Scalars['Boolean']>;
};

export type DomainConnectivityOutput = {
  __typename?: 'DomainConnectivityOutput';
  isConnected: Scalars['Boolean'];
  message: Scalars['String'];
  referenceLink: Scalars['String'];
};

export type DomainNameserverInput = {
  hostname: Scalars['String'];
};

export type DomainNameserverOutput = {
  __typename?: 'DomainNameserverOutput';
  nameServer?: Maybe<NameServerEnum>;
};

export type EmailStatusInput = {
  emailId: Scalars['String'];
};

export type EmailStatusOutput = {
  __typename?: 'EmailStatusOutput';
  emailId: Scalars['String'];
  status: Scalars['String'];
};

export enum FileTypeEnum {
  Image = 'image'
}

export type FormError = {
  __typename?: 'FormError';
  key: Scalars['String'];
  message: Scalars['String'];
};

export type FormatEmailInput = {
  email: Scalars['String'];
};

export type FormatEmailOutput = {
  __typename?: 'FormatEmailOutput';
  formattedEmail: Scalars['String'];
};

export type GenerateUploadUrlInput = {
  count: Scalars['Int'];
  fileType?: Maybe<FileTypeEnum>;
};

export type GenerateUploadUrlOutput = {
  __typename?: 'GenerateUploadUrlOutput';
  uploadUrl: Array<Scalars['String']>;
};

export enum HostnameStatusEnum {
  Alive = 'alive',
  CertificateIssued = 'certificateIssued',
  CertificateIssuing = 'certificateIssuing',
  Created = 'created',
  Error = 'error'
}

/** Type of hostname */
export enum HostnameType {
  Custom = 'custom',
  Oopy = 'oopy'
}

export type IncrementPoolSizeBillInput = {
  currency?: Maybe<Currency>;
  userId: Scalars['String'];
};

export type IncrementPoolSizeBillOutput = {
  __typename?: 'IncrementPoolSizeBillOutput';
  currency: Currency;
  discount: Scalars['Float'];
  expiresAt: Scalars['String'];
  increasePoolSize: Scalars['Int'];
  toBePaidAmount: Scalars['Float'];
  toBePaidDays: Scalars['Int'];
  vatIncluded: Scalars['Boolean'];
};

export type IncrementPoolSizeInput = {
  userId: Scalars['String'];
};

export type IncrementPoolSizeOutput = {
  __typename?: 'IncrementPoolSizeOutput';
  paymentId: Scalars['String'];
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: Maybe<Scalars['Int']>;
  _gt?: Maybe<Scalars['Int']>;
  _gte?: Maybe<Scalars['Int']>;
  _in?: Maybe<Array<Scalars['Int']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Int']>;
  _lte?: Maybe<Scalars['Int']>;
  _neq?: Maybe<Scalars['Int']>;
  _nin?: Maybe<Array<Scalars['Int']>>;
};

export enum IntervalType {
  Day = 'day',
  Infinity = 'infinity',
  Month = 'month',
  Week = 'week',
  Year = 'year'
}

export type IssueAdminTokenInput = {
  hostnameId: Scalars['ID'];
};

export type IssueAdminTokenOutput = {
  __typename?: 'IssueAdminTokenOutput';
  hostnameId: Scalars['ID'];
  token: Scalars['String'];
};

export type MemberSignInInput = {
  email: Scalars['String'];
  hostname: Scalars['String'];
  location?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
};

export type MemberSignInOutput = {
  __typename?: 'MemberSignInOutput';
  accessToken?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};

/** Status of a member */
export enum MemberStatus {
  Active = 'active',
  Created = 'created',
  Pending = 'pending'
}

export type MemberVerificationInput = {
  hostnameId: Scalars['ID'];
  verificationId: Scalars['ID'];
};

export type MemberVerificationOutput = {
  __typename?: 'MemberVerificationOutput';
  accessToken: Scalars['String'];
  email: Scalars['String'];
  hasPassword: Scalars['Boolean'];
  id: Scalars['ID'];
  isNewMember: Scalars['Boolean'];
  status: MemberStatus;
};

export enum MembershipPaymentCurrency {
  Krw = 'KRW',
  Usd = 'USD'
}

export enum MembershipPaymentPg {
  Manual = 'manual',
  Paddle = 'paddle',
  Payple = 'payple',
  Stripe = 'stripe'
}

export enum MembershipPaymentType {
  Manual = 'manual',
  Onetime = 'onetime',
  Subscription = 'subscription'
}

export enum NameServerEnum {
  Cafe24 = 'cafe24',
  Cloudflare = 'cloudflare',
  Gabia = 'gabia',
  Hostingkr = 'hostingkr',
  Porkbun = 'porkbun'
}

export type NextPayBillInput = {
  currency?: Maybe<Currency>;
  userId: Scalars['String'];
};

export type NextPayBillOutput = {
  __typename?: 'NextPayBillOutput';
  currency: Currency;
  discount: Scalars['Float'];
  nextExpiresAt: Scalars['String'];
  nextPayMemo: Scalars['String'];
  nextPayTime: Scalars['String'];
  nextPayTotal: Scalars['Float'];
  vatIncluded?: Maybe<Scalars['Boolean']>;
};

export enum Pg {
  Manual = 'manual',
  Paddle = 'paddle',
  Payple = 'payple',
  Stripe = 'stripe'
}

export type RecurringInfoInput = {
  interval: IntervalType;
  intervalCount?: Maybe<Scalars['Int']>;
};

export type RefundBillInput = {
  userId: Scalars['String'];
};

export type RefundBillItem = {
  __typename?: 'RefundBillItem';
  currency: Currency;
  id: Scalars['ID'];
  paidAmount: Scalars['Float'];
  paidAt: Scalars['String'];
  paidDays: Scalars['Int'];
  paymentId: Scalars['String'];
  pg: Pg;
  refundAmount: Scalars['Float'];
  refundDays: Scalars['Int'];
  refundRate: Scalars['Float'];
};

export type RefundBillOutput = {
  __typename?: 'RefundBillOutput';
  items: Array<RefundBillItem>;
};

export type RefundMemberPaymentInput = {
  amount: Scalars['Int'];
  id: Scalars['ID'];
  memo?: Maybe<Scalars['String']>;
};

export type RefundMemberPaymentOutput = {
  __typename?: 'RefundMemberPaymentOutput';
  id: Scalars['ID'];
};

export type ResumeInput = {
  compareNextPayTime: Scalars['String'];
  userId: Scalars['String'];
};

export type ResumeOutput = {
  __typename?: 'ResumeOutput';
  paymentId: Scalars['String'];
};

export type SignInInput = {
  email: Scalars['String'];
  lang?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  userAgent?: Maybe<Scalars['String']>;
};

export type SignInOutput = {
  __typename?: 'SignInOutput';
  email: Scalars['String'];
  emailId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  token?: Maybe<Scalars['String']>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: Maybe<Scalars['String']>;
  _gt?: Maybe<Scalars['String']>;
  _gte?: Maybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: Maybe<Scalars['String']>;
  _in?: Maybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: Maybe<Scalars['String']>;
  _is_null?: Maybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: Maybe<Scalars['String']>;
  _lt?: Maybe<Scalars['String']>;
  _lte?: Maybe<Scalars['String']>;
  _neq?: Maybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: Maybe<Scalars['String']>;
  _nin?: Maybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: Maybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: Maybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: Maybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: Maybe<Scalars['String']>;
};

export type UpdateEmailInput = {
  email: Scalars['String'];
  userId?: Maybe<Scalars['ID']>;
};

export type UpdateEmailOutput = {
  __typename?: 'UpdateEmailOutput';
  email: Scalars['String'];
  userId: Scalars['ID'];
};

export type UpdateMemberPasswordInput = {
  memberId: Scalars['String'];
  password: Scalars['String'];
};

export type UpdateMemberPasswordOutput = {
  __typename?: 'UpdateMemberPasswordOutput';
  memberId: Scalars['String'];
};

export type UpdateMembershipProductInput = {
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  permission?: Maybe<Scalars['String']>;
};

export type UpdateMembershipProductOutput = {
  __typename?: 'UpdateMembershipProductOutput';
  description: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  permission: Scalars['String'];
};

export type UpdateNotionUrlInput = {
  id: Scalars['ID'];
  notionUrl: Scalars['String'];
};

export type UpdateNotionUrlOutput = {
  __typename?: 'UpdateNotionUrlOutput';
  id: Scalars['ID'];
  notionUrl: Scalars['String'];
  rootPageId: Scalars['String'];
};

export type UpdatePagePasswordInput = {
  id: Scalars['ID'];
  pageId?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
};

export type UpdatePagePasswordOutput = {
  __typename?: 'UpdatePagePasswordOutput';
  id: Scalars['ID'];
};

export type UpdatePasswordInput = {
  password: Scalars['String'];
  userId?: Maybe<Scalars['ID']>;
};

export type UpdatePasswordOutput = {
  __typename?: 'UpdatePasswordOutput';
  password: Scalars['String'];
  userId: Scalars['ID'];
};

export type UpdateReferrerInput = {
  referralInfo: Scalars['String'];
};

export type UpdateReferrerOutput = {
  __typename?: 'UpdateReferrerOutput';
  userId: Scalars['ID'];
};

export type UpgradePlanBillInput = {
  currency?: Maybe<Currency>;
  hostnameId: Scalars['String'];
};

export type UpgradePlanBillOutput = {
  __typename?: 'UpgradePlanBillOutput';
  currency: Currency;
  discount: Scalars['Float'];
  expiresAt: Scalars['String'];
  toBePaidAmount: Scalars['Float'];
  toBePaidDays: Scalars['Int'];
  vatIncluded: Scalars['Boolean'];
};

export type UpgradePlanInput = {
  hostnameId: Scalars['String'];
};

export type UpgradePlanOutput = {
  __typename?: 'UpgradePlanOutput';
  paymentId: Scalars['String'];
};

export type ValidHostnameInput = {
  hostname: Scalars['String'];
  type: HostnameType;
};

export type ValidHostnameOutput = {
  __typename?: 'ValidHostnameOutput';
  hostname: Scalars['String'];
  isValid: Scalars['Boolean'];
};

export type ValidMenuLinkInput = {
  hostnameId: Scalars['ID'];
  url: Scalars['String'];
};

export type ValidMenuLinkOutput = {
  __typename?: 'ValidMenuLinkOutput';
  path?: Maybe<Scalars['String']>;
  url: Scalars['String'];
};

export type ValidNicknameInput = {
  hostnameId: Scalars['ID'];
  nickname: Scalars['String'];
};

export type ValidNicknameOutput = {
  __typename?: 'ValidNicknameOutput';
  hostnameId: Scalars['ID'];
  isValid: Scalars['Boolean'];
  memberId?: Maybe<Scalars['ID']>;
  nickname: Scalars['String'];
};

export type ValidNotionUrlInput = {
  url: Scalars['String'];
};

export type ValidNotionUrlOutput = {
  __typename?: 'ValidNotionUrlOutput';
  isValid: Scalars['Boolean'];
  pageId: Scalars['String'];
  url: Scalars['String'];
};

export type WithdrawalInput = {
  userId?: Maybe<Scalars['String']>;
};

export type WithdrawalOutput = {
  __typename?: 'WithdrawalOutput';
  status: Scalars['String'];
};


/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: Maybe<Scalars['bigint']>;
  _gt?: Maybe<Scalars['bigint']>;
  _gte?: Maybe<Scalars['bigint']>;
  _in?: Maybe<Array<Scalars['bigint']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['bigint']>;
  _lte?: Maybe<Scalars['bigint']>;
  _neq?: Maybe<Scalars['bigint']>;
  _nin?: Maybe<Array<Scalars['bigint']>>;
};

/** columns and relationships of "configuration" */
export type Configuration = {
  __typename?: 'configuration';
  allowRobots: Scalars['Boolean'];
  backgroundColor?: Maybe<Scalars['String']>;
  bodyScript?: Maybe<Scalars['String']>;
  channeltalkId?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamptz'];
  disqus?: Maybe<Scalars['String']>;
  enableSitemap: Scalars['Boolean'];
  excludedIPs: Scalars['jsonb'];
  facebookCustomerChatId?: Maybe<Scalars['String']>;
  facebookPixelId?: Maybe<Scalars['String']>;
  favicon?: Maybe<Scalars['String']>;
  fontColor?: Maybe<Scalars['String']>;
  fonts: Scalars['jsonb'];
  googleAdsense: Scalars['jsonb'];
  googleAnalyticsId?: Maybe<Scalars['String']>;
  headScript?: Maybe<Scalars['String']>;
  hideBreadcrumbs: Scalars['Boolean'];
  hideCollectionProperties: Scalars['Boolean'];
  hideCollectionSearch: Scalars['Boolean'];
  hideFooter: Scalars['Boolean'];
  hideSearch: Scalars['Boolean'];
  /** An object relationship */
  hostname: Hostname;
  hostnameId: Scalars['uuid'];
  id: Scalars['uuid'];
  ogImage?: Maybe<Scalars['String']>;
  ogImageFirst: Scalars['Boolean'];
  showMaple: Scalars['Boolean'];
  showPageview: Scalars['Boolean'];
  showShareButton: Scalars['Boolean'];
  showThemeButton: Scalars['Boolean'];
  theme: Enum_Hostname_Theme_Enum;
  updatedAt: Scalars['timestamptz'];
};


/** columns and relationships of "configuration" */
export type ConfigurationExcludedIPsArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "configuration" */
export type ConfigurationFontsArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "configuration" */
export type ConfigurationGoogleAdsenseArgs = {
  path?: Maybe<Scalars['String']>;
};

/** Boolean expression to filter rows from the table "configuration". All fields are combined with a logical 'AND'. */
export type Configuration_Bool_Exp = {
  _and?: Maybe<Array<Configuration_Bool_Exp>>;
  _not?: Maybe<Configuration_Bool_Exp>;
  _or?: Maybe<Array<Configuration_Bool_Exp>>;
  allowRobots?: Maybe<Boolean_Comparison_Exp>;
  backgroundColor?: Maybe<String_Comparison_Exp>;
  bodyScript?: Maybe<String_Comparison_Exp>;
  channeltalkId?: Maybe<String_Comparison_Exp>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  disqus?: Maybe<String_Comparison_Exp>;
  enableSitemap?: Maybe<Boolean_Comparison_Exp>;
  excludedIPs?: Maybe<Jsonb_Comparison_Exp>;
  facebookCustomerChatId?: Maybe<String_Comparison_Exp>;
  facebookPixelId?: Maybe<String_Comparison_Exp>;
  favicon?: Maybe<String_Comparison_Exp>;
  fontColor?: Maybe<String_Comparison_Exp>;
  fonts?: Maybe<Jsonb_Comparison_Exp>;
  googleAdsense?: Maybe<Jsonb_Comparison_Exp>;
  googleAnalyticsId?: Maybe<String_Comparison_Exp>;
  headScript?: Maybe<String_Comparison_Exp>;
  hideBreadcrumbs?: Maybe<Boolean_Comparison_Exp>;
  hideCollectionProperties?: Maybe<Boolean_Comparison_Exp>;
  hideCollectionSearch?: Maybe<Boolean_Comparison_Exp>;
  hideFooter?: Maybe<Boolean_Comparison_Exp>;
  hideSearch?: Maybe<Boolean_Comparison_Exp>;
  hostname?: Maybe<Hostname_Bool_Exp>;
  hostnameId?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  ogImage?: Maybe<String_Comparison_Exp>;
  ogImageFirst?: Maybe<Boolean_Comparison_Exp>;
  showMaple?: Maybe<Boolean_Comparison_Exp>;
  showPageview?: Maybe<Boolean_Comparison_Exp>;
  showShareButton?: Maybe<Boolean_Comparison_Exp>;
  showThemeButton?: Maybe<Boolean_Comparison_Exp>;
  theme?: Maybe<Enum_Hostname_Theme_Enum_Comparison_Exp>;
  updatedAt?: Maybe<Timestamptz_Comparison_Exp>;
};

/** Ordering options when selecting data from "configuration". */
export type Configuration_Order_By = {
  allowRobots?: Maybe<Order_By>;
  backgroundColor?: Maybe<Order_By>;
  bodyScript?: Maybe<Order_By>;
  channeltalkId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  disqus?: Maybe<Order_By>;
  enableSitemap?: Maybe<Order_By>;
  excludedIPs?: Maybe<Order_By>;
  facebookCustomerChatId?: Maybe<Order_By>;
  facebookPixelId?: Maybe<Order_By>;
  favicon?: Maybe<Order_By>;
  fontColor?: Maybe<Order_By>;
  fonts?: Maybe<Order_By>;
  googleAdsense?: Maybe<Order_By>;
  googleAnalyticsId?: Maybe<Order_By>;
  headScript?: Maybe<Order_By>;
  hideBreadcrumbs?: Maybe<Order_By>;
  hideCollectionProperties?: Maybe<Order_By>;
  hideCollectionSearch?: Maybe<Order_By>;
  hideFooter?: Maybe<Order_By>;
  hideSearch?: Maybe<Order_By>;
  hostname?: Maybe<Hostname_Order_By>;
  hostnameId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  ogImage?: Maybe<Order_By>;
  ogImageFirst?: Maybe<Order_By>;
  showMaple?: Maybe<Order_By>;
  showPageview?: Maybe<Order_By>;
  showShareButton?: Maybe<Order_By>;
  showThemeButton?: Maybe<Order_By>;
  theme?: Maybe<Order_By>;
  updatedAt?: Maybe<Order_By>;
};

/** select columns of table "configuration" */
export enum Configuration_Select_Column {
  /** column name */
  AllowRobots = 'allowRobots',
  /** column name */
  BackgroundColor = 'backgroundColor',
  /** column name */
  BodyScript = 'bodyScript',
  /** column name */
  ChanneltalkId = 'channeltalkId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Disqus = 'disqus',
  /** column name */
  EnableSitemap = 'enableSitemap',
  /** column name */
  ExcludedIPs = 'excludedIPs',
  /** column name */
  FacebookCustomerChatId = 'facebookCustomerChatId',
  /** column name */
  FacebookPixelId = 'facebookPixelId',
  /** column name */
  Favicon = 'favicon',
  /** column name */
  FontColor = 'fontColor',
  /** column name */
  Fonts = 'fonts',
  /** column name */
  GoogleAdsense = 'googleAdsense',
  /** column name */
  GoogleAnalyticsId = 'googleAnalyticsId',
  /** column name */
  HeadScript = 'headScript',
  /** column name */
  HideBreadcrumbs = 'hideBreadcrumbs',
  /** column name */
  HideCollectionProperties = 'hideCollectionProperties',
  /** column name */
  HideCollectionSearch = 'hideCollectionSearch',
  /** column name */
  HideFooter = 'hideFooter',
  /** column name */
  HideSearch = 'hideSearch',
  /** column name */
  HostnameId = 'hostnameId',
  /** column name */
  Id = 'id',
  /** column name */
  OgImage = 'ogImage',
  /** column name */
  OgImageFirst = 'ogImageFirst',
  /** column name */
  ShowMaple = 'showMaple',
  /** column name */
  ShowPageview = 'showPageview',
  /** column name */
  ShowShareButton = 'showShareButton',
  /** column name */
  ShowThemeButton = 'showThemeButton',
  /** column name */
  Theme = 'theme',
  /** column name */
  UpdatedAt = 'updatedAt'
}


/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: Maybe<Scalars['date']>;
  _gt?: Maybe<Scalars['date']>;
  _gte?: Maybe<Scalars['date']>;
  _in?: Maybe<Array<Scalars['date']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['date']>;
  _lte?: Maybe<Scalars['date']>;
  _neq?: Maybe<Scalars['date']>;
  _nin?: Maybe<Array<Scalars['date']>>;
};

export enum Enum_Event_Op_Enum {
  Delete = 'DELETE',
  Insert = 'INSERT',
  Update = 'UPDATE'
}

/** Boolean expression to compare columns of type "enum_event_op_enum". All fields are combined with logical 'AND'. */
export type Enum_Event_Op_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Event_Op_Enum>;
  _in?: Maybe<Array<Enum_Event_Op_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Event_Op_Enum>;
  _nin?: Maybe<Array<Enum_Event_Op_Enum>>;
};

export enum Enum_Event_Type_Enum {
  BillingKey = 'billingKey',
  ChangeToCustom = 'changeToCustom',
  Configuration = 'configuration',
  Custom = 'custom',
  DomainConnectivity = 'domainConnectivity',
  DominTypeSelect = 'dominTypeSelect',
  Error = 'error',
  Hostname = 'hostname',
  HostnameEntered = 'hostnameEntered',
  NotionEntered = 'notionEntered',
  Payment = 'payment',
  Plugin = 'plugin',
  Querystring = 'querystring',
  Signin = 'signin',
  Slug = 'slug',
  User = 'user'
}

/** Boolean expression to compare columns of type "enum_event_type_enum". All fields are combined with logical 'AND'. */
export type Enum_Event_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Event_Type_Enum>;
  _in?: Maybe<Array<Enum_Event_Type_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Event_Type_Enum>;
  _nin?: Maybe<Array<Enum_Event_Type_Enum>>;
};

export enum Enum_Hostname_Plan_Enum {
  Basic = 'basic',
  Gowid = 'gowid',
  Legacy = 'legacy',
  Membership = 'membership',
  Pro = 'pro'
}

/** Boolean expression to compare columns of type "enum_hostname_plan_enum". All fields are combined with logical 'AND'. */
export type Enum_Hostname_Plan_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Hostname_Plan_Enum>;
  _in?: Maybe<Array<Enum_Hostname_Plan_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Hostname_Plan_Enum>;
  _nin?: Maybe<Array<Enum_Hostname_Plan_Enum>>;
};

export enum Enum_Hostname_Status_Enum {
  Alive = 'alive',
  CertificateIssued = 'certificateIssued',
  CertificateIssuing = 'certificateIssuing',
  Created = 'created',
  /** is in error status */
  Error = 'error'
}

/** Boolean expression to compare columns of type "enum_hostname_status_enum". All fields are combined with logical 'AND'. */
export type Enum_Hostname_Status_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Hostname_Status_Enum>;
  _in?: Maybe<Array<Enum_Hostname_Status_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Hostname_Status_Enum>;
  _nin?: Maybe<Array<Enum_Hostname_Status_Enum>>;
};

export enum Enum_Hostname_Theme_Enum {
  Custom = 'custom',
  Dark = 'dark',
  Light = 'light'
}

/** Boolean expression to compare columns of type "enum_hostname_theme_enum". All fields are combined with logical 'AND'. */
export type Enum_Hostname_Theme_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Hostname_Theme_Enum>;
  _in?: Maybe<Array<Enum_Hostname_Theme_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Hostname_Theme_Enum>;
  _nin?: Maybe<Array<Enum_Hostname_Theme_Enum>>;
};

export enum Enum_Hostname_Type_Enum {
  /** custom domain owned by user */
  Custom = 'custom',
  /** oopy.io subdomain */
  Oopy = 'oopy'
}

/** Boolean expression to compare columns of type "enum_hostname_type_enum". All fields are combined with logical 'AND'. */
export type Enum_Hostname_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Hostname_Type_Enum>;
  _in?: Maybe<Array<Enum_Hostname_Type_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Hostname_Type_Enum>;
  _nin?: Maybe<Array<Enum_Hostname_Type_Enum>>;
};

export enum Enum_Membership_Payment_Type_Enum {
  Manual = 'manual',
  Onetime = 'onetime',
  Subscription = 'subscription'
}

/** Boolean expression to compare columns of type "enum_membership_payment_type_enum". All fields are combined with logical 'AND'. */
export type Enum_Membership_Payment_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Membership_Payment_Type_Enum>;
  _in?: Maybe<Array<Enum_Membership_Payment_Type_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Membership_Payment_Type_Enum>;
  _nin?: Maybe<Array<Enum_Membership_Payment_Type_Enum>>;
};

export enum Enum_Payment_Currency_Enum {
  Krw = 'KRW',
  Usd = 'USD'
}

/** Boolean expression to compare columns of type "enum_payment_currency_enum". All fields are combined with logical 'AND'. */
export type Enum_Payment_Currency_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Payment_Currency_Enum>;
  _in?: Maybe<Array<Enum_Payment_Currency_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Payment_Currency_Enum>;
  _nin?: Maybe<Array<Enum_Payment_Currency_Enum>>;
};

export enum Enum_Payment_Pg_Enum {
  Manual = 'manual',
  Paddle = 'paddle',
  Payple = 'payple',
  Stripe = 'stripe'
}

/** Boolean expression to compare columns of type "enum_payment_pg_enum". All fields are combined with logical 'AND'. */
export type Enum_Payment_Pg_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Payment_Pg_Enum>;
  _in?: Maybe<Array<Enum_Payment_Pg_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Payment_Pg_Enum>;
  _nin?: Maybe<Array<Enum_Payment_Pg_Enum>>;
};

export enum Enum_Survey_Type_Enum {
  HostnameConnection = 'hostnameConnection',
  Nps = 'nps'
}

/** Boolean expression to compare columns of type "enum_survey_type_enum". All fields are combined with logical 'AND'. */
export type Enum_Survey_Type_Enum_Comparison_Exp = {
  _eq?: Maybe<Enum_Survey_Type_Enum>;
  _in?: Maybe<Array<Enum_Survey_Type_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Enum_Survey_Type_Enum>;
  _nin?: Maybe<Array<Enum_Survey_Type_Enum>>;
};

/** columns and relationships of "event" */
export type Event = {
  __typename?: 'event';
  createdAt: Scalars['timestamp'];
  data?: Maybe<Scalars['jsonb']>;
  id: Scalars['uuid'];
  op?: Maybe<Enum_Event_Op_Enum>;
  type: Enum_Event_Type_Enum;
  /** An object relationship */
  user?: Maybe<User>;
  userId?: Maybe<Scalars['uuid']>;
};


/** columns and relationships of "event" */
export type EventDataArgs = {
  path?: Maybe<Scalars['String']>;
};

/** order by aggregate values of table "event" */
export type Event_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Event_Max_Order_By>;
  min?: Maybe<Event_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "event". All fields are combined with a logical 'AND'. */
export type Event_Bool_Exp = {
  _and?: Maybe<Array<Event_Bool_Exp>>;
  _not?: Maybe<Event_Bool_Exp>;
  _or?: Maybe<Array<Event_Bool_Exp>>;
  createdAt?: Maybe<Timestamp_Comparison_Exp>;
  data?: Maybe<Jsonb_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  op?: Maybe<Enum_Event_Op_Enum_Comparison_Exp>;
  type?: Maybe<Enum_Event_Type_Enum_Comparison_Exp>;
  user?: Maybe<User_Bool_Exp>;
  userId?: Maybe<Uuid_Comparison_Exp>;
};

/** order by max() on columns of table "event" */
export type Event_Max_Order_By = {
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** order by min() on columns of table "event" */
export type Event_Min_Order_By = {
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** Ordering options when selecting data from "event". */
export type Event_Order_By = {
  createdAt?: Maybe<Order_By>;
  data?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  op?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  user?: Maybe<User_Order_By>;
  userId?: Maybe<Order_By>;
};

/** select columns of table "event" */
export enum Event_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Data = 'data',
  /** column name */
  Id = 'id',
  /** column name */
  Op = 'op',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'userId'
}


/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export type Float8_Comparison_Exp = {
  _eq?: Maybe<Scalars['float8']>;
  _gt?: Maybe<Scalars['float8']>;
  _gte?: Maybe<Scalars['float8']>;
  _in?: Maybe<Array<Scalars['float8']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['float8']>;
  _lte?: Maybe<Scalars['float8']>;
  _neq?: Maybe<Scalars['float8']>;
  _nin?: Maybe<Array<Scalars['float8']>>;
};

/** manage hostnames */
export type Hostname = {
  __typename?: 'hostname';
  /** An object relationship */
  configuration?: Maybe<Configuration>;
  createdAt: Scalars['timestamp'];
  createdDate?: Maybe<Scalars['date']>;
  id: Scalars['uuid'];
  /** An array relationship */
  members: Array<Member>;
  /** An aggregate relationship */
  members_aggregate: Member_Aggregate;
  /** An object relationship */
  membershipConfiguration?: Maybe<MembershipConfiguration>;
  /** An array relationship */
  membershipProducts: Array<MembershipProduct>;
  name: Scalars['String'];
  notionUrl: Scalars['String'];
  plan: Enum_Hostname_Plan_Enum;
  rootPageId: Scalars['uuid'];
  source?: Maybe<Scalars['String']>;
  spaceId: Scalars['uuid'];
  status: Enum_Hostname_Status_Enum;
  tags?: Maybe<Scalars['String']>;
  type?: Maybe<Enum_Hostname_Type_Enum>;
  /** An object relationship */
  user: User;
  userId: Scalars['uuid'];
};


/** manage hostnames */
export type HostnameMembersArgs = {
  distinct_on?: Maybe<Array<Member_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Order_By>>;
  where?: Maybe<Member_Bool_Exp>;
};


/** manage hostnames */
export type HostnameMembers_AggregateArgs = {
  distinct_on?: Maybe<Array<Member_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Order_By>>;
  where?: Maybe<Member_Bool_Exp>;
};


/** manage hostnames */
export type HostnameMembershipProductsArgs = {
  distinct_on?: Maybe<Array<MembershipProduct_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<MembershipProduct_Order_By>>;
  where?: Maybe<MembershipProduct_Bool_Exp>;
};

/** aggregated selection of "hostname" */
export type Hostname_Aggregate = {
  __typename?: 'hostname_aggregate';
  aggregate?: Maybe<Hostname_Aggregate_Fields>;
  nodes: Array<Hostname>;
};

/** aggregate fields of "hostname" */
export type Hostname_Aggregate_Fields = {
  __typename?: 'hostname_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Hostname_Max_Fields>;
  min?: Maybe<Hostname_Min_Fields>;
};


/** aggregate fields of "hostname" */
export type Hostname_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Hostname_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "hostname" */
export type Hostname_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Hostname_Max_Order_By>;
  min?: Maybe<Hostname_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "hostname". All fields are combined with a logical 'AND'. */
export type Hostname_Bool_Exp = {
  _and?: Maybe<Array<Hostname_Bool_Exp>>;
  _not?: Maybe<Hostname_Bool_Exp>;
  _or?: Maybe<Array<Hostname_Bool_Exp>>;
  configuration?: Maybe<Configuration_Bool_Exp>;
  createdAt?: Maybe<Timestamp_Comparison_Exp>;
  createdDate?: Maybe<Date_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  members?: Maybe<Member_Bool_Exp>;
  membershipConfiguration?: Maybe<MembershipConfiguration_Bool_Exp>;
  membershipProducts?: Maybe<MembershipProduct_Bool_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  notionUrl?: Maybe<String_Comparison_Exp>;
  plan?: Maybe<Enum_Hostname_Plan_Enum_Comparison_Exp>;
  rootPageId?: Maybe<Uuid_Comparison_Exp>;
  source?: Maybe<String_Comparison_Exp>;
  spaceId?: Maybe<Uuid_Comparison_Exp>;
  status?: Maybe<Enum_Hostname_Status_Enum_Comparison_Exp>;
  tags?: Maybe<String_Comparison_Exp>;
  type?: Maybe<Enum_Hostname_Type_Enum_Comparison_Exp>;
  user?: Maybe<User_Bool_Exp>;
  userId?: Maybe<Uuid_Comparison_Exp>;
};

/** aggregate max on columns */
export type Hostname_Max_Fields = {
  __typename?: 'hostname_max_fields';
  createdAt?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  notionUrl?: Maybe<Scalars['String']>;
  rootPageId?: Maybe<Scalars['uuid']>;
  source?: Maybe<Scalars['String']>;
  spaceId?: Maybe<Scalars['uuid']>;
  tags?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "hostname" */
export type Hostname_Max_Order_By = {
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  notionUrl?: Maybe<Order_By>;
  rootPageId?: Maybe<Order_By>;
  source?: Maybe<Order_By>;
  spaceId?: Maybe<Order_By>;
  tags?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Hostname_Min_Fields = {
  __typename?: 'hostname_min_fields';
  createdAt?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  notionUrl?: Maybe<Scalars['String']>;
  rootPageId?: Maybe<Scalars['uuid']>;
  source?: Maybe<Scalars['String']>;
  spaceId?: Maybe<Scalars['uuid']>;
  tags?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "hostname" */
export type Hostname_Min_Order_By = {
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  notionUrl?: Maybe<Order_By>;
  rootPageId?: Maybe<Order_By>;
  source?: Maybe<Order_By>;
  spaceId?: Maybe<Order_By>;
  tags?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** Ordering options when selecting data from "hostname". */
export type Hostname_Order_By = {
  configuration?: Maybe<Configuration_Order_By>;
  createdAt?: Maybe<Order_By>;
  createdDate?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  members_aggregate?: Maybe<Member_Aggregate_Order_By>;
  membershipConfiguration?: Maybe<MembershipConfiguration_Order_By>;
  membershipProducts_aggregate?: Maybe<MembershipProduct_Aggregate_Order_By>;
  name?: Maybe<Order_By>;
  notionUrl?: Maybe<Order_By>;
  plan?: Maybe<Order_By>;
  rootPageId?: Maybe<Order_By>;
  source?: Maybe<Order_By>;
  spaceId?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  tags?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  user?: Maybe<User_Order_By>;
  userId?: Maybe<Order_By>;
};

/** select columns of table "hostname" */
export enum Hostname_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  NotionUrl = 'notionUrl',
  /** column name */
  Plan = 'plan',
  /** column name */
  RootPageId = 'rootPageId',
  /** column name */
  Source = 'source',
  /** column name */
  SpaceId = 'spaceId',
  /** column name */
  Status = 'status',
  /** column name */
  Tags = 'tags',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'userId'
}


export type Jsonb_Cast_Exp = {
  String?: Maybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: Maybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: Maybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: Maybe<Scalars['jsonb']>;
  _eq?: Maybe<Scalars['jsonb']>;
  _gt?: Maybe<Scalars['jsonb']>;
  _gte?: Maybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: Maybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: Maybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: Maybe<Array<Scalars['String']>>;
  _in?: Maybe<Array<Scalars['jsonb']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['jsonb']>;
  _lte?: Maybe<Scalars['jsonb']>;
  _neq?: Maybe<Scalars['jsonb']>;
  _nin?: Maybe<Array<Scalars['jsonb']>>;
};

/** columns and relationships of "member" */
export type Member = {
  __typename?: 'member';
  createdAt: Scalars['timestamp'];
  email: Scalars['String'];
  /** An object relationship */
  hostname: Hostname;
  hostnameId: Scalars['uuid'];
  id: Scalars['uuid'];
  /** A computed field, executes function "check_blocked" */
  isBlocked?: Maybe<Scalars['Boolean']>;
  /** An array relationship */
  memberPayments: Array<MemberPayment>;
  password?: Maybe<Scalars['String']>;
  promotionCode?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
};


/** columns and relationships of "member" */
export type MemberMemberPaymentsArgs = {
  distinct_on?: Maybe<Array<MemberPayment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<MemberPayment_Order_By>>;
  where?: Maybe<MemberPayment_Bool_Exp>;
};

/** columns and relationships of "memberPayment" */
export type MemberPayment = {
  __typename?: 'memberPayment';
  amountReceived: Scalars['Int'];
  createdAt: Scalars['timestamp'];
  error?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  invoiceUrl?: Maybe<Scalars['String']>;
  /** An object relationship */
  member?: Maybe<Member>;
  memberId?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  membershipProductPrice: MembershipProductPrice;
  membershipProductPriceId: Scalars['uuid'];
  paymentId: Scalars['String'];
};

/** order by aggregate values of table "memberPayment" */
export type MemberPayment_Aggregate_Order_By = {
  avg?: Maybe<MemberPayment_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<MemberPayment_Max_Order_By>;
  min?: Maybe<MemberPayment_Min_Order_By>;
  stddev?: Maybe<MemberPayment_Stddev_Order_By>;
  stddev_pop?: Maybe<MemberPayment_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<MemberPayment_Stddev_Samp_Order_By>;
  sum?: Maybe<MemberPayment_Sum_Order_By>;
  var_pop?: Maybe<MemberPayment_Var_Pop_Order_By>;
  var_samp?: Maybe<MemberPayment_Var_Samp_Order_By>;
  variance?: Maybe<MemberPayment_Variance_Order_By>;
};

/** order by avg() on columns of table "memberPayment" */
export type MemberPayment_Avg_Order_By = {
  amountReceived?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "memberPayment". All fields are combined with a logical 'AND'. */
export type MemberPayment_Bool_Exp = {
  _and?: Maybe<Array<MemberPayment_Bool_Exp>>;
  _not?: Maybe<MemberPayment_Bool_Exp>;
  _or?: Maybe<Array<MemberPayment_Bool_Exp>>;
  amountReceived?: Maybe<Int_Comparison_Exp>;
  createdAt?: Maybe<Timestamp_Comparison_Exp>;
  error?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  invoiceUrl?: Maybe<String_Comparison_Exp>;
  member?: Maybe<Member_Bool_Exp>;
  memberId?: Maybe<Uuid_Comparison_Exp>;
  membershipProductPrice?: Maybe<MembershipProductPrice_Bool_Exp>;
  membershipProductPriceId?: Maybe<Uuid_Comparison_Exp>;
  paymentId?: Maybe<String_Comparison_Exp>;
};

/** order by max() on columns of table "memberPayment" */
export type MemberPayment_Max_Order_By = {
  amountReceived?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  error?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoiceUrl?: Maybe<Order_By>;
  memberId?: Maybe<Order_By>;
  membershipProductPriceId?: Maybe<Order_By>;
  paymentId?: Maybe<Order_By>;
};

/** order by min() on columns of table "memberPayment" */
export type MemberPayment_Min_Order_By = {
  amountReceived?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  error?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoiceUrl?: Maybe<Order_By>;
  memberId?: Maybe<Order_By>;
  membershipProductPriceId?: Maybe<Order_By>;
  paymentId?: Maybe<Order_By>;
};

/** Ordering options when selecting data from "memberPayment". */
export type MemberPayment_Order_By = {
  amountReceived?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  error?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  invoiceUrl?: Maybe<Order_By>;
  member?: Maybe<Member_Order_By>;
  memberId?: Maybe<Order_By>;
  membershipProductPrice?: Maybe<MembershipProductPrice_Order_By>;
  membershipProductPriceId?: Maybe<Order_By>;
  paymentId?: Maybe<Order_By>;
};

/** select columns of table "memberPayment" */
export enum MemberPayment_Select_Column {
  /** column name */
  AmountReceived = 'amountReceived',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Error = 'error',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceUrl = 'invoiceUrl',
  /** column name */
  MemberId = 'memberId',
  /** column name */
  MembershipProductPriceId = 'membershipProductPriceId',
  /** column name */
  PaymentId = 'paymentId'
}

/** order by stddev() on columns of table "memberPayment" */
export type MemberPayment_Stddev_Order_By = {
  amountReceived?: Maybe<Order_By>;
};

/** order by stddev_pop() on columns of table "memberPayment" */
export type MemberPayment_Stddev_Pop_Order_By = {
  amountReceived?: Maybe<Order_By>;
};

/** order by stddev_samp() on columns of table "memberPayment" */
export type MemberPayment_Stddev_Samp_Order_By = {
  amountReceived?: Maybe<Order_By>;
};

/** order by sum() on columns of table "memberPayment" */
export type MemberPayment_Sum_Order_By = {
  amountReceived?: Maybe<Order_By>;
};

/** order by var_pop() on columns of table "memberPayment" */
export type MemberPayment_Var_Pop_Order_By = {
  amountReceived?: Maybe<Order_By>;
};

/** order by var_samp() on columns of table "memberPayment" */
export type MemberPayment_Var_Samp_Order_By = {
  amountReceived?: Maybe<Order_By>;
};

/** order by variance() on columns of table "memberPayment" */
export type MemberPayment_Variance_Order_By = {
  amountReceived?: Maybe<Order_By>;
};

/** aggregated selection of "member" */
export type Member_Aggregate = {
  __typename?: 'member_aggregate';
  aggregate?: Maybe<Member_Aggregate_Fields>;
  nodes: Array<Member>;
};

/** aggregate fields of "member" */
export type Member_Aggregate_Fields = {
  __typename?: 'member_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Member_Max_Fields>;
  min?: Maybe<Member_Min_Fields>;
};


/** aggregate fields of "member" */
export type Member_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Member_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "member" */
export type Member_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Member_Max_Order_By>;
  min?: Maybe<Member_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "member". All fields are combined with a logical 'AND'. */
export type Member_Bool_Exp = {
  _and?: Maybe<Array<Member_Bool_Exp>>;
  _not?: Maybe<Member_Bool_Exp>;
  _or?: Maybe<Array<Member_Bool_Exp>>;
  createdAt?: Maybe<Timestamp_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  hostname?: Maybe<Hostname_Bool_Exp>;
  hostnameId?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  isBlocked?: Maybe<Boolean_Comparison_Exp>;
  memberPayments?: Maybe<MemberPayment_Bool_Exp>;
  password?: Maybe<String_Comparison_Exp>;
  promotionCode?: Maybe<String_Comparison_Exp>;
  stripeId?: Maybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Member_Max_Fields = {
  __typename?: 'member_max_fields';
  createdAt?: Maybe<Scalars['timestamp']>;
  email?: Maybe<Scalars['String']>;
  hostnameId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  password?: Maybe<Scalars['String']>;
  promotionCode?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "member" */
export type Member_Max_Order_By = {
  createdAt?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  hostnameId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  password?: Maybe<Order_By>;
  promotionCode?: Maybe<Order_By>;
  stripeId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Member_Min_Fields = {
  __typename?: 'member_min_fields';
  createdAt?: Maybe<Scalars['timestamp']>;
  email?: Maybe<Scalars['String']>;
  hostnameId?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  password?: Maybe<Scalars['String']>;
  promotionCode?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "member" */
export type Member_Min_Order_By = {
  createdAt?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  hostnameId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  password?: Maybe<Order_By>;
  promotionCode?: Maybe<Order_By>;
  stripeId?: Maybe<Order_By>;
};

/** Ordering options when selecting data from "member". */
export type Member_Order_By = {
  createdAt?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  hostname?: Maybe<Hostname_Order_By>;
  hostnameId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  isBlocked?: Maybe<Order_By>;
  memberPayments_aggregate?: Maybe<MemberPayment_Aggregate_Order_By>;
  password?: Maybe<Order_By>;
  promotionCode?: Maybe<Order_By>;
  stripeId?: Maybe<Order_By>;
};

/** select columns of table "member" */
export enum Member_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  HostnameId = 'hostnameId',
  /** column name */
  Id = 'id',
  /** column name */
  Password = 'password',
  /** column name */
  PromotionCode = 'promotionCode',
  /** column name */
  StripeId = 'stripeId'
}

/** configurations for membership */
export type MembershipConfiguration = {
  __typename?: 'membershipConfiguration';
  brandColor: Scalars['String'];
  cookiePolicy?: Maybe<Scalars['String']>;
  defaultPermission: Scalars['String'];
  hasStripeSecretKey?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  hostname: Hostname;
  hostnameId: Scalars['uuid'];
  id: Scalars['uuid'];
  pg: Enum_Payment_Pg_Enum;
  serviceName: Scalars['String'];
  signupTerms?: Maybe<Scalars['jsonb']>;
};


/** configurations for membership */
export type MembershipConfigurationSignupTermsArgs = {
  path?: Maybe<Scalars['String']>;
};

/** Boolean expression to filter rows from the table "membershipConfiguration". All fields are combined with a logical 'AND'. */
export type MembershipConfiguration_Bool_Exp = {
  _and?: Maybe<Array<MembershipConfiguration_Bool_Exp>>;
  _not?: Maybe<MembershipConfiguration_Bool_Exp>;
  _or?: Maybe<Array<MembershipConfiguration_Bool_Exp>>;
  brandColor?: Maybe<String_Comparison_Exp>;
  cookiePolicy?: Maybe<String_Comparison_Exp>;
  defaultPermission?: Maybe<String_Comparison_Exp>;
  hasStripeSecretKey?: Maybe<Boolean_Comparison_Exp>;
  hostname?: Maybe<Hostname_Bool_Exp>;
  hostnameId?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  pg?: Maybe<Enum_Payment_Pg_Enum_Comparison_Exp>;
  serviceName?: Maybe<String_Comparison_Exp>;
  signupTerms?: Maybe<Jsonb_Comparison_Exp>;
};

/** Ordering options when selecting data from "membershipConfiguration". */
export type MembershipConfiguration_Order_By = {
  brandColor?: Maybe<Order_By>;
  cookiePolicy?: Maybe<Order_By>;
  defaultPermission?: Maybe<Order_By>;
  hasStripeSecretKey?: Maybe<Order_By>;
  hostname?: Maybe<Hostname_Order_By>;
  hostnameId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  pg?: Maybe<Order_By>;
  serviceName?: Maybe<Order_By>;
  signupTerms?: Maybe<Order_By>;
};

/** select columns of table "membershipConfiguration" */
export enum MembershipConfiguration_Select_Column {
  /** column name */
  BrandColor = 'brandColor',
  /** column name */
  CookiePolicy = 'cookiePolicy',
  /** column name */
  DefaultPermission = 'defaultPermission',
  /** column name */
  HostnameId = 'hostnameId',
  /** column name */
  Id = 'id',
  /** column name */
  Pg = 'pg',
  /** column name */
  ServiceName = 'serviceName',
  /** column name */
  SignupTerms = 'signupTerms'
}

/** columns and relationships of "membershipProduct" */
export type MembershipProduct = {
  __typename?: 'membershipProduct';
  childProductId?: Maybe<Scalars['uuid']>;
  /** A computed field, executes function "get_children_membershipproducts" */
  childrenProducts?: Maybe<Array<MembershipProduct>>;
  createdAt: Scalars['timestamp'];
  description?: Maybe<Scalars['String']>;
  /** An object relationship */
  hostname: Hostname;
  hostnameId: Scalars['uuid'];
  id: Scalars['uuid'];
  /** An array relationship */
  membershipProductPrices: Array<MembershipProductPrice>;
  name: Scalars['String'];
  /** An array relationship */
  parentProducts: Array<MembershipProduct>;
  permission: Scalars['String'];
  stripeId?: Maybe<Scalars['String']>;
};


/** columns and relationships of "membershipProduct" */
export type MembershipProductChildrenProductsArgs = {
  distinct_on?: Maybe<Array<MembershipProduct_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<MembershipProduct_Order_By>>;
  where?: Maybe<MembershipProduct_Bool_Exp>;
};


/** columns and relationships of "membershipProduct" */
export type MembershipProductMembershipProductPricesArgs = {
  distinct_on?: Maybe<Array<MembershipProductPrice_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<MembershipProductPrice_Order_By>>;
  where?: Maybe<MembershipProductPrice_Bool_Exp>;
};


/** columns and relationships of "membershipProduct" */
export type MembershipProductParentProductsArgs = {
  distinct_on?: Maybe<Array<MembershipProduct_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<MembershipProduct_Order_By>>;
  where?: Maybe<MembershipProduct_Bool_Exp>;
};

/** columns and relationships of "membershipProductPrice" */
export type MembershipProductPrice = {
  __typename?: 'membershipProductPrice';
  createdAt: Scalars['timestamp'];
  currency: Enum_Payment_Currency_Enum;
  /** Format unitAmount to currency unit */
  currencyUnitAmount?: Maybe<Scalars['float8']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  isActive: Scalars['Boolean'];
  /** An array relationship */
  memberPayments: Array<MemberPayment>;
  /** An object relationship */
  membershipProduct: MembershipProduct;
  membershipProductId: Scalars['uuid'];
  name?: Maybe<Scalars['String']>;
  pg: Enum_Payment_Pg_Enum;
  recurring: Scalars['jsonb'];
  stripeId?: Maybe<Scalars['String']>;
  trialPeriodDays: Scalars['Int'];
  type: Enum_Membership_Payment_Type_Enum;
  unitAmount: Scalars['Int'];
};


/** columns and relationships of "membershipProductPrice" */
export type MembershipProductPriceMemberPaymentsArgs = {
  distinct_on?: Maybe<Array<MemberPayment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<MemberPayment_Order_By>>;
  where?: Maybe<MemberPayment_Bool_Exp>;
};


/** columns and relationships of "membershipProductPrice" */
export type MembershipProductPriceRecurringArgs = {
  path?: Maybe<Scalars['String']>;
};

/** order by aggregate values of table "membershipProductPrice" */
export type MembershipProductPrice_Aggregate_Order_By = {
  avg?: Maybe<MembershipProductPrice_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<MembershipProductPrice_Max_Order_By>;
  min?: Maybe<MembershipProductPrice_Min_Order_By>;
  stddev?: Maybe<MembershipProductPrice_Stddev_Order_By>;
  stddev_pop?: Maybe<MembershipProductPrice_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<MembershipProductPrice_Stddev_Samp_Order_By>;
  sum?: Maybe<MembershipProductPrice_Sum_Order_By>;
  var_pop?: Maybe<MembershipProductPrice_Var_Pop_Order_By>;
  var_samp?: Maybe<MembershipProductPrice_Var_Samp_Order_By>;
  variance?: Maybe<MembershipProductPrice_Variance_Order_By>;
};

/** order by avg() on columns of table "membershipProductPrice" */
export type MembershipProductPrice_Avg_Order_By = {
  trialPeriodDays?: Maybe<Order_By>;
  unitAmount?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "membershipProductPrice". All fields are combined with a logical 'AND'. */
export type MembershipProductPrice_Bool_Exp = {
  _and?: Maybe<Array<MembershipProductPrice_Bool_Exp>>;
  _not?: Maybe<MembershipProductPrice_Bool_Exp>;
  _or?: Maybe<Array<MembershipProductPrice_Bool_Exp>>;
  createdAt?: Maybe<Timestamp_Comparison_Exp>;
  currency?: Maybe<Enum_Payment_Currency_Enum_Comparison_Exp>;
  currencyUnitAmount?: Maybe<Float8_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  isActive?: Maybe<Boolean_Comparison_Exp>;
  memberPayments?: Maybe<MemberPayment_Bool_Exp>;
  membershipProduct?: Maybe<MembershipProduct_Bool_Exp>;
  membershipProductId?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  pg?: Maybe<Enum_Payment_Pg_Enum_Comparison_Exp>;
  recurring?: Maybe<Jsonb_Comparison_Exp>;
  stripeId?: Maybe<String_Comparison_Exp>;
  trialPeriodDays?: Maybe<Int_Comparison_Exp>;
  type?: Maybe<Enum_Membership_Payment_Type_Enum_Comparison_Exp>;
  unitAmount?: Maybe<Int_Comparison_Exp>;
};

/** order by max() on columns of table "membershipProductPrice" */
export type MembershipProductPrice_Max_Order_By = {
  createdAt?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  membershipProductId?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  stripeId?: Maybe<Order_By>;
  trialPeriodDays?: Maybe<Order_By>;
  unitAmount?: Maybe<Order_By>;
};

/** order by min() on columns of table "membershipProductPrice" */
export type MembershipProductPrice_Min_Order_By = {
  createdAt?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  membershipProductId?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  stripeId?: Maybe<Order_By>;
  trialPeriodDays?: Maybe<Order_By>;
  unitAmount?: Maybe<Order_By>;
};

/** Ordering options when selecting data from "membershipProductPrice". */
export type MembershipProductPrice_Order_By = {
  createdAt?: Maybe<Order_By>;
  currency?: Maybe<Order_By>;
  currencyUnitAmount?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  isActive?: Maybe<Order_By>;
  memberPayments_aggregate?: Maybe<MemberPayment_Aggregate_Order_By>;
  membershipProduct?: Maybe<MembershipProduct_Order_By>;
  membershipProductId?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  pg?: Maybe<Order_By>;
  recurring?: Maybe<Order_By>;
  stripeId?: Maybe<Order_By>;
  trialPeriodDays?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  unitAmount?: Maybe<Order_By>;
};

/** select columns of table "membershipProductPrice" */
export enum MembershipProductPrice_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Currency = 'currency',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'isActive',
  /** column name */
  MembershipProductId = 'membershipProductId',
  /** column name */
  Name = 'name',
  /** column name */
  Pg = 'pg',
  /** column name */
  Recurring = 'recurring',
  /** column name */
  StripeId = 'stripeId',
  /** column name */
  TrialPeriodDays = 'trialPeriodDays',
  /** column name */
  Type = 'type',
  /** column name */
  UnitAmount = 'unitAmount'
}

/** order by stddev() on columns of table "membershipProductPrice" */
export type MembershipProductPrice_Stddev_Order_By = {
  trialPeriodDays?: Maybe<Order_By>;
  unitAmount?: Maybe<Order_By>;
};

/** order by stddev_pop() on columns of table "membershipProductPrice" */
export type MembershipProductPrice_Stddev_Pop_Order_By = {
  trialPeriodDays?: Maybe<Order_By>;
  unitAmount?: Maybe<Order_By>;
};

/** order by stddev_samp() on columns of table "membershipProductPrice" */
export type MembershipProductPrice_Stddev_Samp_Order_By = {
  trialPeriodDays?: Maybe<Order_By>;
  unitAmount?: Maybe<Order_By>;
};

/** order by sum() on columns of table "membershipProductPrice" */
export type MembershipProductPrice_Sum_Order_By = {
  trialPeriodDays?: Maybe<Order_By>;
  unitAmount?: Maybe<Order_By>;
};

/** order by var_pop() on columns of table "membershipProductPrice" */
export type MembershipProductPrice_Var_Pop_Order_By = {
  trialPeriodDays?: Maybe<Order_By>;
  unitAmount?: Maybe<Order_By>;
};

/** order by var_samp() on columns of table "membershipProductPrice" */
export type MembershipProductPrice_Var_Samp_Order_By = {
  trialPeriodDays?: Maybe<Order_By>;
  unitAmount?: Maybe<Order_By>;
};

/** order by variance() on columns of table "membershipProductPrice" */
export type MembershipProductPrice_Variance_Order_By = {
  trialPeriodDays?: Maybe<Order_By>;
  unitAmount?: Maybe<Order_By>;
};

/** order by aggregate values of table "membershipProduct" */
export type MembershipProduct_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<MembershipProduct_Max_Order_By>;
  min?: Maybe<MembershipProduct_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "membershipProduct". All fields are combined with a logical 'AND'. */
export type MembershipProduct_Bool_Exp = {
  _and?: Maybe<Array<MembershipProduct_Bool_Exp>>;
  _not?: Maybe<MembershipProduct_Bool_Exp>;
  _or?: Maybe<Array<MembershipProduct_Bool_Exp>>;
  childProductId?: Maybe<Uuid_Comparison_Exp>;
  childrenProducts?: Maybe<MembershipProduct_Bool_Exp>;
  createdAt?: Maybe<Timestamp_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  hostname?: Maybe<Hostname_Bool_Exp>;
  hostnameId?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  membershipProductPrices?: Maybe<MembershipProductPrice_Bool_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  parentProducts?: Maybe<MembershipProduct_Bool_Exp>;
  permission?: Maybe<String_Comparison_Exp>;
  stripeId?: Maybe<String_Comparison_Exp>;
};

/** order by max() on columns of table "membershipProduct" */
export type MembershipProduct_Max_Order_By = {
  childProductId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  hostnameId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  permission?: Maybe<Order_By>;
  stripeId?: Maybe<Order_By>;
};

/** order by min() on columns of table "membershipProduct" */
export type MembershipProduct_Min_Order_By = {
  childProductId?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  hostnameId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  permission?: Maybe<Order_By>;
  stripeId?: Maybe<Order_By>;
};

/** Ordering options when selecting data from "membershipProduct". */
export type MembershipProduct_Order_By = {
  childProductId?: Maybe<Order_By>;
  childrenProducts_aggregate?: Maybe<MembershipProduct_Aggregate_Order_By>;
  createdAt?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  hostname?: Maybe<Hostname_Order_By>;
  hostnameId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  membershipProductPrices_aggregate?: Maybe<MembershipProductPrice_Aggregate_Order_By>;
  name?: Maybe<Order_By>;
  parentProducts_aggregate?: Maybe<MembershipProduct_Aggregate_Order_By>;
  permission?: Maybe<Order_By>;
  stripeId?: Maybe<Order_By>;
};

/** select columns of table "membershipProduct" */
export enum MembershipProduct_Select_Column {
  /** column name */
  ChildProductId = 'childProductId',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Description = 'description',
  /** column name */
  HostnameId = 'hostnameId',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Permission = 'permission',
  /** column name */
  StripeId = 'stripeId'
}

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  applyGowidPromotion: ApplyGowidPromotionOutput;
  changeEmailAddress: ChangeEmailAddressOutput;
  changeOopyToCustom: ChangeOopyToCustomOutput;
  clearHostnameDataCache: ClearHostnameDataCacheOutput;
  clearPageCache: ClearPageCacheOutput;
  connectDomain: ConnectDomainOutput;
  createManualMember: CreateManualMemberOutput;
  createManualPermission: CreateManualPermissionOutput;
  createMembershipHostname: CreateMembershipHostnameOutput;
  createMembershipProduct: CreateMembershipProductOutput;
  createMembershipProductPrice: CreateMembershipProductPriceOutput;
  createMembershipTestUser: CreateMembershipTestUserOutput;
  createRedirection: CreateRedirectionOutput;
  createSetupIntent: CreateSetupIntentOutput;
  createSlug: CreateSlugOutput;
  deleteBillingKey: DeleteBillingKeyOutput;
  deleteHostname: DeleteHostnameOutput;
  deleteMember: DeleteMemberOutput;
  deleteMembershipProduct: DeleteMembershipProductOutput;
  generateUploadUrl: GenerateUploadUrlOutput;
  incrementPoolSize: IncrementPoolSizeOutput;
  issueAdminToken: IssueAdminTokenOutput;
  memberSignIn: MemberSignInOutput;
  memberVerification: MemberVerificationOutput;
  refundMemberPayment: RefundMemberPaymentOutput;
  resume: ResumeOutput;
  signIn: SignInOutput;
  updateEmail: UpdateEmailOutput;
  updateMemberPassword: UpdateMemberPasswordOutput;
  updateMembershipProduct: UpdateMembershipProductOutput;
  updateNotionUrl: UpdateNotionUrlOutput;
  updatePagePassword: UpdatePagePasswordOutput;
  updatePassword: UpdatePasswordOutput;
  updateReferrer: UpdateReferrerOutput;
  upgradePlan: UpgradePlanOutput;
  withdrawal: WithdrawalOutput;
};


/** mutation root */
export type Mutation_RootApplyGowidPromotionArgs = {
  input: ApplyGowidPromotionInput;
};


/** mutation root */
export type Mutation_RootChangeEmailAddressArgs = {
  input: ChangeEmailAddressInput;
};


/** mutation root */
export type Mutation_RootChangeOopyToCustomArgs = {
  input: ChangeOopyToCustomInput;
};


/** mutation root */
export type Mutation_RootClearHostnameDataCacheArgs = {
  input: ClearHostnameDataCacheInput;
};


/** mutation root */
export type Mutation_RootClearPageCacheArgs = {
  input: ClearPageCacheInput;
};


/** mutation root */
export type Mutation_RootConnectDomainArgs = {
  input: ConnectDomainInput;
};


/** mutation root */
export type Mutation_RootCreateManualMemberArgs = {
  input: CreateManualMemberInput;
};


/** mutation root */
export type Mutation_RootCreateManualPermissionArgs = {
  input: CreateManualPermissionInput;
};


/** mutation root */
export type Mutation_RootCreateMembershipHostnameArgs = {
  input: CreateMembershipHostnameInput;
};


/** mutation root */
export type Mutation_RootCreateMembershipProductArgs = {
  input: CreateMembershipProductInput;
};


/** mutation root */
export type Mutation_RootCreateMembershipProductPriceArgs = {
  input: CreateMembershipProductPriceInput;
};


/** mutation root */
export type Mutation_RootCreateMembershipTestUserArgs = {
  input: CreateMembershipTestUserInput;
};


/** mutation root */
export type Mutation_RootCreateRedirectionArgs = {
  input: CreateRedirectionInput;
};


/** mutation root */
export type Mutation_RootCreateSetupIntentArgs = {
  input: CreateSetupIntentInput;
};


/** mutation root */
export type Mutation_RootCreateSlugArgs = {
  input: CreateSlugInput;
};


/** mutation root */
export type Mutation_RootDeleteBillingKeyArgs = {
  input: DeleteBillingKeyInput;
};


/** mutation root */
export type Mutation_RootDeleteHostnameArgs = {
  input: DeleteHostnameInput;
};


/** mutation root */
export type Mutation_RootDeleteMemberArgs = {
  input: DeleteMemberInput;
};


/** mutation root */
export type Mutation_RootDeleteMembershipProductArgs = {
  input: DeleteMembershipProductInput;
};


/** mutation root */
export type Mutation_RootGenerateUploadUrlArgs = {
  input: GenerateUploadUrlInput;
};


/** mutation root */
export type Mutation_RootIncrementPoolSizeArgs = {
  input: IncrementPoolSizeInput;
};


/** mutation root */
export type Mutation_RootIssueAdminTokenArgs = {
  input: IssueAdminTokenInput;
};


/** mutation root */
export type Mutation_RootMemberSignInArgs = {
  input: MemberSignInInput;
};


/** mutation root */
export type Mutation_RootMemberVerificationArgs = {
  input: MemberVerificationInput;
};


/** mutation root */
export type Mutation_RootRefundMemberPaymentArgs = {
  input: RefundMemberPaymentInput;
};


/** mutation root */
export type Mutation_RootResumeArgs = {
  input: ResumeInput;
};


/** mutation root */
export type Mutation_RootSignInArgs = {
  input: SignInInput;
};


/** mutation root */
export type Mutation_RootUpdateEmailArgs = {
  input: UpdateEmailInput;
};


/** mutation root */
export type Mutation_RootUpdateMemberPasswordArgs = {
  input: UpdateMemberPasswordInput;
};


/** mutation root */
export type Mutation_RootUpdateMembershipProductArgs = {
  input: UpdateMembershipProductInput;
};


/** mutation root */
export type Mutation_RootUpdateNotionUrlArgs = {
  input: UpdateNotionUrlInput;
};


/** mutation root */
export type Mutation_RootUpdatePagePasswordArgs = {
  input: UpdatePagePasswordInput;
};


/** mutation root */
export type Mutation_RootUpdatePasswordArgs = {
  input: UpdatePasswordInput;
};


/** mutation root */
export type Mutation_RootUpdateReferrerArgs = {
  input: UpdateReferrerInput;
};


/** mutation root */
export type Mutation_RootUpgradePlanArgs = {
  input: UpgradePlanInput;
};


/** mutation root */
export type Mutation_RootWithdrawalArgs = {
  input: WithdrawalInput;
};


/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: Maybe<Scalars['numeric']>;
  _gt?: Maybe<Scalars['numeric']>;
  _gte?: Maybe<Scalars['numeric']>;
  _in?: Maybe<Array<Scalars['numeric']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['numeric']>;
  _lte?: Maybe<Scalars['numeric']>;
  _neq?: Maybe<Scalars['numeric']>;
  _nin?: Maybe<Array<Scalars['numeric']>>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "payment" */
export type Payment = {
  __typename?: 'payment';
  createdAt: Scalars['timestamp'];
  expiresAt?: Maybe<Scalars['timestamp']>;
  id: Scalars['uuid'];
  membershipPoolSize?: Maybe<Scalars['numeric']>;
  paidAt?: Maybe<Scalars['timestamp']>;
  poolSize?: Maybe<Scalars['numeric']>;
  price: Scalars['float8'];
  /** An object relationship */
  user: User;
  userId: Scalars['uuid'];
};

/** order by aggregate values of table "payment" */
export type Payment_Aggregate_Order_By = {
  avg?: Maybe<Payment_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Payment_Max_Order_By>;
  min?: Maybe<Payment_Min_Order_By>;
  stddev?: Maybe<Payment_Stddev_Order_By>;
  stddev_pop?: Maybe<Payment_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Payment_Stddev_Samp_Order_By>;
  sum?: Maybe<Payment_Sum_Order_By>;
  var_pop?: Maybe<Payment_Var_Pop_Order_By>;
  var_samp?: Maybe<Payment_Var_Samp_Order_By>;
  variance?: Maybe<Payment_Variance_Order_By>;
};

/** order by avg() on columns of table "payment" */
export type Payment_Avg_Order_By = {
  membershipPoolSize?: Maybe<Order_By>;
  poolSize?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "payment". All fields are combined with a logical 'AND'. */
export type Payment_Bool_Exp = {
  _and?: Maybe<Array<Payment_Bool_Exp>>;
  _not?: Maybe<Payment_Bool_Exp>;
  _or?: Maybe<Array<Payment_Bool_Exp>>;
  createdAt?: Maybe<Timestamp_Comparison_Exp>;
  expiresAt?: Maybe<Timestamp_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  membershipPoolSize?: Maybe<Numeric_Comparison_Exp>;
  paidAt?: Maybe<Timestamp_Comparison_Exp>;
  poolSize?: Maybe<Numeric_Comparison_Exp>;
  price?: Maybe<Float8_Comparison_Exp>;
  user?: Maybe<User_Bool_Exp>;
  userId?: Maybe<Uuid_Comparison_Exp>;
};

/** order by max() on columns of table "payment" */
export type Payment_Max_Order_By = {
  createdAt?: Maybe<Order_By>;
  expiresAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  membershipPoolSize?: Maybe<Order_By>;
  paidAt?: Maybe<Order_By>;
  poolSize?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** order by min() on columns of table "payment" */
export type Payment_Min_Order_By = {
  createdAt?: Maybe<Order_By>;
  expiresAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  membershipPoolSize?: Maybe<Order_By>;
  paidAt?: Maybe<Order_By>;
  poolSize?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** Ordering options when selecting data from "payment". */
export type Payment_Order_By = {
  createdAt?: Maybe<Order_By>;
  expiresAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  membershipPoolSize?: Maybe<Order_By>;
  paidAt?: Maybe<Order_By>;
  poolSize?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  user?: Maybe<User_Order_By>;
  userId?: Maybe<Order_By>;
};

/** select columns of table "payment" */
export enum Payment_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ExpiresAt = 'expiresAt',
  /** column name */
  Id = 'id',
  /** column name */
  MembershipPoolSize = 'membershipPoolSize',
  /** column name */
  PaidAt = 'paidAt',
  /** column name */
  PoolSize = 'poolSize',
  /** column name */
  Price = 'price',
  /** column name */
  UserId = 'userId'
}

/** order by stddev() on columns of table "payment" */
export type Payment_Stddev_Order_By = {
  membershipPoolSize?: Maybe<Order_By>;
  poolSize?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
};

/** order by stddev_pop() on columns of table "payment" */
export type Payment_Stddev_Pop_Order_By = {
  membershipPoolSize?: Maybe<Order_By>;
  poolSize?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
};

/** order by stddev_samp() on columns of table "payment" */
export type Payment_Stddev_Samp_Order_By = {
  membershipPoolSize?: Maybe<Order_By>;
  poolSize?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
};

/** order by sum() on columns of table "payment" */
export type Payment_Sum_Order_By = {
  membershipPoolSize?: Maybe<Order_By>;
  poolSize?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
};

/** order by var_pop() on columns of table "payment" */
export type Payment_Var_Pop_Order_By = {
  membershipPoolSize?: Maybe<Order_By>;
  poolSize?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
};

/** order by var_samp() on columns of table "payment" */
export type Payment_Var_Samp_Order_By = {
  membershipPoolSize?: Maybe<Order_By>;
  poolSize?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
};

/** order by variance() on columns of table "payment" */
export type Payment_Variance_Order_By = {
  membershipPoolSize?: Maybe<Order_By>;
  poolSize?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
};

export type Query_Root = {
  __typename?: 'query_root';
  billingInfo: BillingInfoOutput;
  canConnectDomain: CanConnectDomainOutput;
  checkMemberEmail: CheckMemberEmailOutput;
  /** fetch data from the table: "configuration" */
  configuration: Array<Configuration>;
  /** fetch data from the table: "configuration" using primary key columns */
  configuration_by_pk?: Maybe<Configuration>;
  domainConnectivity: DomainConnectivityOutput;
  domainNameserver: DomainNameserverOutput;
  emailStatus: EmailStatusOutput;
  /** fetch data from the table: "event" */
  event: Array<Event>;
  /** fetch data from the table: "event" using primary key columns */
  event_by_pk?: Maybe<Event>;
  formatEmail: FormatEmailOutput;
  /** fetch data from the table: "hostname" */
  hostname: Array<Hostname>;
  /** fetch aggregated fields from the table: "hostname" */
  hostname_aggregate: Hostname_Aggregate;
  /** fetch data from the table: "hostname" using primary key columns */
  hostname_by_pk?: Maybe<Hostname>;
  incrementPoolSizeBill: IncrementPoolSizeBillOutput;
  /** fetch data from the table: "member" */
  member: Array<Member>;
  /** fetch data from the table: "memberPayment" */
  memberPayment: Array<MemberPayment>;
  /** fetch data from the table: "memberPayment" using primary key columns */
  memberPayment_by_pk?: Maybe<MemberPayment>;
  /** fetch aggregated fields from the table: "member" */
  member_aggregate: Member_Aggregate;
  /** fetch data from the table: "member" using primary key columns */
  member_by_pk?: Maybe<Member>;
  /** fetch data from the table: "membershipConfiguration" */
  membershipConfiguration: Array<MembershipConfiguration>;
  /** fetch data from the table: "membershipConfiguration" using primary key columns */
  membershipConfiguration_by_pk?: Maybe<MembershipConfiguration>;
  /** fetch data from the table: "membershipProduct" */
  membershipProduct: Array<MembershipProduct>;
  /** fetch data from the table: "membershipProductPrice" */
  membershipProductPrice: Array<MembershipProductPrice>;
  /** fetch data from the table: "membershipProductPrice" using primary key columns */
  membershipProductPrice_by_pk?: Maybe<MembershipProductPrice>;
  /** fetch data from the table: "membershipProduct" using primary key columns */
  membershipProduct_by_pk?: Maybe<MembershipProduct>;
  nextPayBill: NextPayBillOutput;
  /** fetch data from the table: "payment" */
  payment: Array<Payment>;
  /** fetch data from the table: "payment" using primary key columns */
  payment_by_pk?: Maybe<Payment>;
  refundBill: RefundBillOutput;
  /** fetch data from the table: "survey" */
  survey: Array<Survey>;
  /** fetch data from the table: "survey" using primary key columns */
  survey_by_pk?: Maybe<Survey>;
  upgradePlanBill: UpgradePlanBillOutput;
  /** fetch data from the table: "user" */
  user: Array<User>;
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: User_Aggregate;
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>;
  /** fetch data from the table: "user_view" */
  user_view: Array<User_View>;
  validHostname: ValidHostnameOutput;
  validMenuLink: ValidMenuLinkOutput;
  validNickname: ValidNicknameOutput;
  validNotionUrl: ValidNotionUrlOutput;
};


export type Query_RootBillingInfoArgs = {
  input: BillingInfoInput;
};


export type Query_RootCanConnectDomainArgs = {
  input: CanConnectDomainInput;
};


export type Query_RootCheckMemberEmailArgs = {
  input: CheckMemberEmailInput;
};


export type Query_RootConfigurationArgs = {
  distinct_on?: Maybe<Array<Configuration_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Configuration_Order_By>>;
  where?: Maybe<Configuration_Bool_Exp>;
};


export type Query_RootConfiguration_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootDomainConnectivityArgs = {
  input: DomainConnectivityInput;
};


export type Query_RootDomainNameserverArgs = {
  input: DomainNameserverInput;
};


export type Query_RootEmailStatusArgs = {
  input: EmailStatusInput;
};


export type Query_RootEventArgs = {
  distinct_on?: Maybe<Array<Event_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Order_By>>;
  where?: Maybe<Event_Bool_Exp>;
};


export type Query_RootEvent_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootFormatEmailArgs = {
  input: FormatEmailInput;
};


export type Query_RootHostnameArgs = {
  distinct_on?: Maybe<Array<Hostname_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hostname_Order_By>>;
  where?: Maybe<Hostname_Bool_Exp>;
};


export type Query_RootHostname_AggregateArgs = {
  distinct_on?: Maybe<Array<Hostname_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hostname_Order_By>>;
  where?: Maybe<Hostname_Bool_Exp>;
};


export type Query_RootHostname_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootIncrementPoolSizeBillArgs = {
  input: IncrementPoolSizeBillInput;
};


export type Query_RootMemberArgs = {
  distinct_on?: Maybe<Array<Member_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Order_By>>;
  where?: Maybe<Member_Bool_Exp>;
};


export type Query_RootMemberPaymentArgs = {
  distinct_on?: Maybe<Array<MemberPayment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<MemberPayment_Order_By>>;
  where?: Maybe<MemberPayment_Bool_Exp>;
};


export type Query_RootMemberPayment_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootMember_AggregateArgs = {
  distinct_on?: Maybe<Array<Member_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Order_By>>;
  where?: Maybe<Member_Bool_Exp>;
};


export type Query_RootMember_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootMembershipConfigurationArgs = {
  distinct_on?: Maybe<Array<MembershipConfiguration_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<MembershipConfiguration_Order_By>>;
  where?: Maybe<MembershipConfiguration_Bool_Exp>;
};


export type Query_RootMembershipConfiguration_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootMembershipProductArgs = {
  distinct_on?: Maybe<Array<MembershipProduct_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<MembershipProduct_Order_By>>;
  where?: Maybe<MembershipProduct_Bool_Exp>;
};


export type Query_RootMembershipProductPriceArgs = {
  distinct_on?: Maybe<Array<MembershipProductPrice_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<MembershipProductPrice_Order_By>>;
  where?: Maybe<MembershipProductPrice_Bool_Exp>;
};


export type Query_RootMembershipProductPrice_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootMembershipProduct_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNextPayBillArgs = {
  input: NextPayBillInput;
};


export type Query_RootPaymentArgs = {
  distinct_on?: Maybe<Array<Payment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payment_Order_By>>;
  where?: Maybe<Payment_Bool_Exp>;
};


export type Query_RootPayment_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootRefundBillArgs = {
  input: RefundBillInput;
};


export type Query_RootSurveyArgs = {
  distinct_on?: Maybe<Array<Survey_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Survey_Order_By>>;
  where?: Maybe<Survey_Bool_Exp>;
};


export type Query_RootSurvey_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootUpgradePlanBillArgs = {
  input: UpgradePlanBillInput;
};


export type Query_RootUserArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};


export type Query_RootUser_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};


export type Query_RootUser_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootUser_ViewArgs = {
  distinct_on?: Maybe<Array<User_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_View_Order_By>>;
  where?: Maybe<User_View_Bool_Exp>;
};


export type Query_RootValidHostnameArgs = {
  input: ValidHostnameInput;
};


export type Query_RootValidMenuLinkArgs = {
  input: ValidMenuLinkInput;
};


export type Query_RootValidNicknameArgs = {
  input: ValidNicknameInput;
};


export type Query_RootValidNotionUrlArgs = {
  input: ValidNotionUrlInput;
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "configuration" */
  configuration: Array<Configuration>;
  /** fetch data from the table: "configuration" using primary key columns */
  configuration_by_pk?: Maybe<Configuration>;
  /** fetch data from the table: "event" */
  event: Array<Event>;
  /** fetch data from the table: "event" using primary key columns */
  event_by_pk?: Maybe<Event>;
  /** fetch data from the table: "hostname" */
  hostname: Array<Hostname>;
  /** fetch aggregated fields from the table: "hostname" */
  hostname_aggregate: Hostname_Aggregate;
  /** fetch data from the table: "hostname" using primary key columns */
  hostname_by_pk?: Maybe<Hostname>;
  /** fetch data from the table: "member" */
  member: Array<Member>;
  /** fetch data from the table: "memberPayment" */
  memberPayment: Array<MemberPayment>;
  /** fetch data from the table: "memberPayment" using primary key columns */
  memberPayment_by_pk?: Maybe<MemberPayment>;
  /** fetch aggregated fields from the table: "member" */
  member_aggregate: Member_Aggregate;
  /** fetch data from the table: "member" using primary key columns */
  member_by_pk?: Maybe<Member>;
  /** fetch data from the table: "membershipConfiguration" */
  membershipConfiguration: Array<MembershipConfiguration>;
  /** fetch data from the table: "membershipConfiguration" using primary key columns */
  membershipConfiguration_by_pk?: Maybe<MembershipConfiguration>;
  /** fetch data from the table: "membershipProduct" */
  membershipProduct: Array<MembershipProduct>;
  /** fetch data from the table: "membershipProductPrice" */
  membershipProductPrice: Array<MembershipProductPrice>;
  /** fetch data from the table: "membershipProductPrice" using primary key columns */
  membershipProductPrice_by_pk?: Maybe<MembershipProductPrice>;
  /** fetch data from the table: "membershipProduct" using primary key columns */
  membershipProduct_by_pk?: Maybe<MembershipProduct>;
  /** fetch data from the table: "payment" */
  payment: Array<Payment>;
  /** fetch data from the table: "payment" using primary key columns */
  payment_by_pk?: Maybe<Payment>;
  /** fetch data from the table: "survey" */
  survey: Array<Survey>;
  /** fetch data from the table: "survey" using primary key columns */
  survey_by_pk?: Maybe<Survey>;
  /** fetch data from the table: "user" */
  user: Array<User>;
  /** fetch aggregated fields from the table: "user" */
  user_aggregate: User_Aggregate;
  /** fetch data from the table: "user" using primary key columns */
  user_by_pk?: Maybe<User>;
  /** fetch data from the table: "user_view" */
  user_view: Array<User_View>;
};


export type Subscription_RootConfigurationArgs = {
  distinct_on?: Maybe<Array<Configuration_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Configuration_Order_By>>;
  where?: Maybe<Configuration_Bool_Exp>;
};


export type Subscription_RootConfiguration_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootEventArgs = {
  distinct_on?: Maybe<Array<Event_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Order_By>>;
  where?: Maybe<Event_Bool_Exp>;
};


export type Subscription_RootEvent_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootHostnameArgs = {
  distinct_on?: Maybe<Array<Hostname_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hostname_Order_By>>;
  where?: Maybe<Hostname_Bool_Exp>;
};


export type Subscription_RootHostname_AggregateArgs = {
  distinct_on?: Maybe<Array<Hostname_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hostname_Order_By>>;
  where?: Maybe<Hostname_Bool_Exp>;
};


export type Subscription_RootHostname_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootMemberArgs = {
  distinct_on?: Maybe<Array<Member_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Order_By>>;
  where?: Maybe<Member_Bool_Exp>;
};


export type Subscription_RootMemberPaymentArgs = {
  distinct_on?: Maybe<Array<MemberPayment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<MemberPayment_Order_By>>;
  where?: Maybe<MemberPayment_Bool_Exp>;
};


export type Subscription_RootMemberPayment_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootMember_AggregateArgs = {
  distinct_on?: Maybe<Array<Member_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Member_Order_By>>;
  where?: Maybe<Member_Bool_Exp>;
};


export type Subscription_RootMember_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootMembershipConfigurationArgs = {
  distinct_on?: Maybe<Array<MembershipConfiguration_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<MembershipConfiguration_Order_By>>;
  where?: Maybe<MembershipConfiguration_Bool_Exp>;
};


export type Subscription_RootMembershipConfiguration_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootMembershipProductArgs = {
  distinct_on?: Maybe<Array<MembershipProduct_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<MembershipProduct_Order_By>>;
  where?: Maybe<MembershipProduct_Bool_Exp>;
};


export type Subscription_RootMembershipProductPriceArgs = {
  distinct_on?: Maybe<Array<MembershipProductPrice_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<MembershipProductPrice_Order_By>>;
  where?: Maybe<MembershipProductPrice_Bool_Exp>;
};


export type Subscription_RootMembershipProductPrice_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootMembershipProduct_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootPaymentArgs = {
  distinct_on?: Maybe<Array<Payment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payment_Order_By>>;
  where?: Maybe<Payment_Bool_Exp>;
};


export type Subscription_RootPayment_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootSurveyArgs = {
  distinct_on?: Maybe<Array<Survey_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Survey_Order_By>>;
  where?: Maybe<Survey_Bool_Exp>;
};


export type Subscription_RootSurvey_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootUserArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};


export type Subscription_RootUser_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};


export type Subscription_RootUser_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootUser_ViewArgs = {
  distinct_on?: Maybe<Array<User_View_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_View_Order_By>>;
  where?: Maybe<User_View_Bool_Exp>;
};

/** columns and relationships of "survey" */
export type Survey = {
  __typename?: 'survey';
  createdAt: Scalars['timestamp'];
  data: Scalars['jsonb'];
  id: Scalars['uuid'];
  type: Enum_Survey_Type_Enum;
  /** An object relationship */
  user?: Maybe<User>;
  userId?: Maybe<Scalars['uuid']>;
};


/** columns and relationships of "survey" */
export type SurveyDataArgs = {
  path?: Maybe<Scalars['String']>;
};

/** order by aggregate values of table "survey" */
export type Survey_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Survey_Max_Order_By>;
  min?: Maybe<Survey_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "survey". All fields are combined with a logical 'AND'. */
export type Survey_Bool_Exp = {
  _and?: Maybe<Array<Survey_Bool_Exp>>;
  _not?: Maybe<Survey_Bool_Exp>;
  _or?: Maybe<Array<Survey_Bool_Exp>>;
  createdAt?: Maybe<Timestamp_Comparison_Exp>;
  data?: Maybe<Jsonb_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  type?: Maybe<Enum_Survey_Type_Enum_Comparison_Exp>;
  user?: Maybe<User_Bool_Exp>;
  userId?: Maybe<Uuid_Comparison_Exp>;
};

/** order by max() on columns of table "survey" */
export type Survey_Max_Order_By = {
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** order by min() on columns of table "survey" */
export type Survey_Min_Order_By = {
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** Ordering options when selecting data from "survey". */
export type Survey_Order_By = {
  createdAt?: Maybe<Order_By>;
  data?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  user?: Maybe<User_Order_By>;
  userId?: Maybe<Order_By>;
};

/** select columns of table "survey" */
export enum Survey_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Data = 'data',
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'userId'
}


/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamp']>;
  _gt?: Maybe<Scalars['timestamp']>;
  _gte?: Maybe<Scalars['timestamp']>;
  _in?: Maybe<Array<Scalars['timestamp']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamp']>;
  _lte?: Maybe<Scalars['timestamp']>;
  _neq?: Maybe<Scalars['timestamp']>;
  _nin?: Maybe<Array<Scalars['timestamp']>>;
};


/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamptz']>;
  _gt?: Maybe<Scalars['timestamptz']>;
  _gte?: Maybe<Scalars['timestamptz']>;
  _in?: Maybe<Array<Scalars['timestamptz']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamptz']>;
  _lte?: Maybe<Scalars['timestamptz']>;
  _neq?: Maybe<Scalars['timestamptz']>;
  _nin?: Maybe<Array<Scalars['timestamptz']>>;
};

/** manage users */
export type User = {
  __typename?: 'user';
  billingKey?: Maybe<Scalars['String']>;
  createdAt: Scalars['timestamp'];
  email: Scalars['String'];
  /** An array relationship */
  events: Array<Event>;
  /** An array relationship */
  hostnames: Array<Hostname>;
  /** An aggregate relationship */
  hostnames_aggregate: Hostname_Aggregate;
  id: Scalars['uuid'];
  lang: Scalars['String'];
  nextPayTime: Scalars['timestamp'];
  /** An array relationship */
  payments: Array<Payment>;
  pg?: Maybe<Enum_Payment_Pg_Enum>;
  /** An array relationship */
  referees: Array<User>;
  /** An aggregate relationship */
  referees_aggregate: User_Aggregate;
  /** An object relationship */
  referrer?: Maybe<User>;
  referrerId?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  surveys: Array<Survey>;
  tags?: Maybe<Scalars['String']>;
  /** An object relationship */
  user_view?: Maybe<User_View>;
};


/** manage users */
export type UserEventsArgs = {
  distinct_on?: Maybe<Array<Event_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Event_Order_By>>;
  where?: Maybe<Event_Bool_Exp>;
};


/** manage users */
export type UserHostnamesArgs = {
  distinct_on?: Maybe<Array<Hostname_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hostname_Order_By>>;
  where?: Maybe<Hostname_Bool_Exp>;
};


/** manage users */
export type UserHostnames_AggregateArgs = {
  distinct_on?: Maybe<Array<Hostname_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hostname_Order_By>>;
  where?: Maybe<Hostname_Bool_Exp>;
};


/** manage users */
export type UserPaymentsArgs = {
  distinct_on?: Maybe<Array<Payment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payment_Order_By>>;
  where?: Maybe<Payment_Bool_Exp>;
};


/** manage users */
export type UserRefereesArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};


/** manage users */
export type UserReferees_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};


/** manage users */
export type UserSurveysArgs = {
  distinct_on?: Maybe<Array<Survey_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Survey_Order_By>>;
  where?: Maybe<Survey_Bool_Exp>;
};

/** aggregated selection of "user" */
export type User_Aggregate = {
  __typename?: 'user_aggregate';
  aggregate?: Maybe<User_Aggregate_Fields>;
  nodes: Array<User>;
};

/** aggregate fields of "user" */
export type User_Aggregate_Fields = {
  __typename?: 'user_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_Max_Fields>;
  min?: Maybe<User_Min_Fields>;
};


/** aggregate fields of "user" */
export type User_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user" */
export type User_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<User_Max_Order_By>;
  min?: Maybe<User_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "user". All fields are combined with a logical 'AND'. */
export type User_Bool_Exp = {
  _and?: Maybe<Array<User_Bool_Exp>>;
  _not?: Maybe<User_Bool_Exp>;
  _or?: Maybe<Array<User_Bool_Exp>>;
  billingKey?: Maybe<String_Comparison_Exp>;
  createdAt?: Maybe<Timestamp_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  events?: Maybe<Event_Bool_Exp>;
  hostnames?: Maybe<Hostname_Bool_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  lang?: Maybe<String_Comparison_Exp>;
  nextPayTime?: Maybe<Timestamp_Comparison_Exp>;
  payments?: Maybe<Payment_Bool_Exp>;
  pg?: Maybe<Enum_Payment_Pg_Enum_Comparison_Exp>;
  referees?: Maybe<User_Bool_Exp>;
  referrer?: Maybe<User_Bool_Exp>;
  referrerId?: Maybe<Uuid_Comparison_Exp>;
  surveys?: Maybe<Survey_Bool_Exp>;
  tags?: Maybe<String_Comparison_Exp>;
  user_view?: Maybe<User_View_Bool_Exp>;
};

/** aggregate max on columns */
export type User_Max_Fields = {
  __typename?: 'user_max_fields';
  billingKey?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lang?: Maybe<Scalars['String']>;
  nextPayTime?: Maybe<Scalars['timestamp']>;
  referrerId?: Maybe<Scalars['uuid']>;
  tags?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "user" */
export type User_Max_Order_By = {
  billingKey?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lang?: Maybe<Order_By>;
  nextPayTime?: Maybe<Order_By>;
  referrerId?: Maybe<Order_By>;
  tags?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type User_Min_Fields = {
  __typename?: 'user_min_fields';
  billingKey?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['timestamp']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  lang?: Maybe<Scalars['String']>;
  nextPayTime?: Maybe<Scalars['timestamp']>;
  referrerId?: Maybe<Scalars['uuid']>;
  tags?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "user" */
export type User_Min_Order_By = {
  billingKey?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  lang?: Maybe<Order_By>;
  nextPayTime?: Maybe<Order_By>;
  referrerId?: Maybe<Order_By>;
  tags?: Maybe<Order_By>;
};

/** Ordering options when selecting data from "user". */
export type User_Order_By = {
  billingKey?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  events_aggregate?: Maybe<Event_Aggregate_Order_By>;
  hostnames_aggregate?: Maybe<Hostname_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  lang?: Maybe<Order_By>;
  nextPayTime?: Maybe<Order_By>;
  payments_aggregate?: Maybe<Payment_Aggregate_Order_By>;
  pg?: Maybe<Order_By>;
  referees_aggregate?: Maybe<User_Aggregate_Order_By>;
  referrer?: Maybe<User_Order_By>;
  referrerId?: Maybe<Order_By>;
  surveys_aggregate?: Maybe<Survey_Aggregate_Order_By>;
  tags?: Maybe<Order_By>;
  user_view?: Maybe<User_View_Order_By>;
};

/** select columns of table "user" */
export enum User_Select_Column {
  /** column name */
  BillingKey = 'billingKey',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Lang = 'lang',
  /** column name */
  NextPayTime = 'nextPayTime',
  /** column name */
  Pg = 'pg',
  /** column name */
  ReferrerId = 'referrerId',
  /** column name */
  Tags = 'tags'
}

/** columns and relationships of "user_view" */
export type User_View = {
  __typename?: 'user_view';
  hasBillingKey?: Maybe<Scalars['Boolean']>;
  hasPassword?: Maybe<Scalars['Boolean']>;
  hostnamesCount?: Maybe<Scalars['bigint']>;
  maxExpiredAt?: Maybe<Scalars['timestamp']>;
  paid?: Maybe<Scalars['Boolean']>;
  refereeCount?: Maybe<Scalars['bigint']>;
  totalPoolSize?: Maybe<Scalars['numeric']>;
  userId?: Maybe<Scalars['uuid']>;
  validCouponCount?: Maybe<Scalars['bigint']>;
  validHostnamesCount?: Maybe<Scalars['bigint']>;
};

/** Boolean expression to filter rows from the table "user_view". All fields are combined with a logical 'AND'. */
export type User_View_Bool_Exp = {
  _and?: Maybe<Array<User_View_Bool_Exp>>;
  _not?: Maybe<User_View_Bool_Exp>;
  _or?: Maybe<Array<User_View_Bool_Exp>>;
  hasBillingKey?: Maybe<Boolean_Comparison_Exp>;
  hasPassword?: Maybe<Boolean_Comparison_Exp>;
  hostnamesCount?: Maybe<Bigint_Comparison_Exp>;
  maxExpiredAt?: Maybe<Timestamp_Comparison_Exp>;
  paid?: Maybe<Boolean_Comparison_Exp>;
  refereeCount?: Maybe<Bigint_Comparison_Exp>;
  totalPoolSize?: Maybe<Numeric_Comparison_Exp>;
  userId?: Maybe<Uuid_Comparison_Exp>;
  validCouponCount?: Maybe<Bigint_Comparison_Exp>;
  validHostnamesCount?: Maybe<Bigint_Comparison_Exp>;
};

/** Ordering options when selecting data from "user_view". */
export type User_View_Order_By = {
  hasBillingKey?: Maybe<Order_By>;
  hasPassword?: Maybe<Order_By>;
  hostnamesCount?: Maybe<Order_By>;
  maxExpiredAt?: Maybe<Order_By>;
  paid?: Maybe<Order_By>;
  refereeCount?: Maybe<Order_By>;
  totalPoolSize?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
  validCouponCount?: Maybe<Order_By>;
  validHostnamesCount?: Maybe<Order_By>;
};

/** select columns of table "user_view" */
export enum User_View_Select_Column {
  /** column name */
  HasBillingKey = 'hasBillingKey',
  /** column name */
  HasPassword = 'hasPassword',
  /** column name */
  HostnamesCount = 'hostnamesCount',
  /** column name */
  MaxExpiredAt = 'maxExpiredAt',
  /** column name */
  Paid = 'paid',
  /** column name */
  RefereeCount = 'refereeCount',
  /** column name */
  TotalPoolSize = 'totalPoolSize',
  /** column name */
  UserId = 'userId',
  /** column name */
  ValidCouponCount = 'validCouponCount',
  /** column name */
  ValidHostnamesCount = 'validHostnamesCount'
}


/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: Maybe<Scalars['uuid']>;
  _gt?: Maybe<Scalars['uuid']>;
  _gte?: Maybe<Scalars['uuid']>;
  _in?: Maybe<Array<Scalars['uuid']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['uuid']>;
  _lte?: Maybe<Scalars['uuid']>;
  _neq?: Maybe<Scalars['uuid']>;
  _nin?: Maybe<Array<Scalars['uuid']>>;
};

export type DashboardQueryVariables = Exact<{ [key: string]: never; }>;


export type DashboardQuery = (
  { __typename?: 'query_root' }
  & { total: (
    { __typename?: 'hostname_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'hostname_aggregate_fields' }
      & Pick<Hostname_Aggregate_Fields, 'count'>
    )> }
  ), before: (
    { __typename?: 'hostname_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'hostname_aggregate_fields' }
      & Pick<Hostname_Aggregate_Fields, 'count'>
    )> }
  ), after: (
    { __typename?: 'hostname_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'hostname_aggregate_fields' }
      & Pick<Hostname_Aggregate_Fields, 'count'>
    )> }
  ), total_users: (
    { __typename?: 'user_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'user_aggregate_fields' }
      & Pick<User_Aggregate_Fields, 'count'>
    )> }
  ), paid_users: (
    { __typename?: 'user_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'user_aggregate_fields' }
      & Pick<User_Aggregate_Fields, 'count'>
    )> }
  ), multiple_hostname_users: (
    { __typename?: 'user_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'user_aggregate_fields' }
      & Pick<User_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type HostnameQueryVariables = Exact<{
  where?: Maybe<Hostname_Bool_Exp>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type HostnameQuery = (
  { __typename?: 'query_root' }
  & { hostname: Array<(
    { __typename?: 'hostname' }
    & Pick<Hostname, 'id' | 'name' | 'tags'>
  )> }
);

export type MembershipDataQueryVariables = Exact<{ [key: string]: never; }>;


export type MembershipDataQuery = (
  { __typename?: 'query_root' }
  & { hostname: Array<(
    { __typename?: 'hostname' }
    & Pick<Hostname, 'id' | 'name' | 'createdAt'>
    & { members_aggregate: (
      { __typename?: 'member_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'member_aggregate_fields' }
        & Pick<Member_Aggregate_Fields, 'count'>
      )> }
    ), membershipConfiguration?: Maybe<(
      { __typename?: 'membershipConfiguration' }
      & Pick<MembershipConfiguration, 'defaultPermission' | 'signupTerms' | 'pg'>
    )>, membershipProducts: Array<(
      { __typename?: 'membershipProduct' }
      & Pick<MembershipProduct, 'id' | 'name' | 'description' | 'permission'>
      & { membershipProductPrices: Array<(
        { __typename?: 'membershipProductPrice' }
        & Pick<MembershipProductPrice, 'id' | 'name' | 'description' | 'currencyUnitAmount' | 'currency'>
        & { memberPayments: Array<(
          { __typename?: 'memberPayment' }
          & Pick<MemberPayment, 'id' | 'amountReceived'>
        )> }
      )> }
    )>, user: (
      { __typename?: 'user' }
      & Pick<User, 'id' | 'email' | 'nextPayTime'>
      & { user_view?: Maybe<(
        { __typename?: 'user_view' }
        & Pick<User_View, 'hasBillingKey'>
      )>, payments: Array<(
        { __typename?: 'payment' }
        & Pick<Payment, 'id' | 'price' | 'createdAt' | 'expiresAt' | 'paidAt' | 'poolSize' | 'membershipPoolSize'>
      )> }
    ) }
  )> }
);

export type NextPayBillQueryVariables = Exact<{
  userId: Scalars['String'];
}>;


export type NextPayBillQuery = (
  { __typename?: 'query_root' }
  & { nextPayBill: (
    { __typename?: 'NextPayBillOutput' }
    & Pick<NextPayBillOutput, 'nextPayTotal' | 'currency' | 'discount' | 'nextPayTime' | 'vatIncluded'>
  ) }
);

export type UserQueryVariables = Exact<{
  where?: Maybe<User_Bool_Exp>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type UserQuery = (
  { __typename?: 'query_root' }
  & { user: Array<(
    { __typename?: 'user' }
    & Pick<User, 'id' | 'email' | 'createdAt' | 'referrerId'>
    & { hostnames: Array<(
      { __typename?: 'hostname' }
      & Pick<Hostname, 'name' | 'tags' | 'plan'>
    )>, user_view?: Maybe<(
      { __typename?: 'user_view' }
      & Pick<User_View, 'hasPassword' | 'hasBillingKey'>
    )>, events: Array<(
      { __typename?: 'event' }
      & Pick<Event, 'id' | 'type' | 'data'>
    )> }
  )> }
);

export type UserAggregationsQueryVariables = Exact<{
  createdAt: Scalars['timestamp'];
}>;


export type UserAggregationsQuery = (
  { __typename?: 'query_root' }
  & { total: (
    { __typename?: 'user_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'user_aggregate_fields' }
      & Pick<User_Aggregate_Fields, 'count'>
    )> }
  ), hasHostname: (
    { __typename?: 'user_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'user_aggregate_fields' }
      & Pick<User_Aggregate_Fields, 'count'>
    )> }
  ), hasBillingKey: (
    { __typename?: 'user_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'user_aggregate_fields' }
      & Pick<User_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type UserWithEventsQueryVariables = Exact<{
  where?: Maybe<User_Bool_Exp>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type UserWithEventsQuery = (
  { __typename?: 'query_root' }
  & { user: Array<(
    { __typename?: 'user' }
    & Pick<User, 'id' | 'email' | 'createdAt'>
    & { hostnames: Array<(
      { __typename?: 'hostname' }
      & Pick<Hostname, 'name' | 'tags'>
    )>, user_view?: Maybe<(
      { __typename?: 'user_view' }
      & Pick<User_View, 'hasBillingKey'>
    )>, events: Array<(
      { __typename?: 'event' }
      & Pick<Event, 'type' | 'op' | 'data' | 'createdAt'>
    )> }
  )> }
);

export type UserWithEventsByIdQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type UserWithEventsByIdQuery = (
  { __typename?: 'query_root' }
  & { user_by_pk?: Maybe<(
    { __typename?: 'user' }
    & Pick<User, 'id' | 'email' | 'createdAt'>
    & { hostnames: Array<(
      { __typename?: 'hostname' }
      & Pick<Hostname, 'name'>
    )>, user_view?: Maybe<(
      { __typename?: 'user_view' }
      & Pick<User_View, 'hasBillingKey'>
    )>, events: Array<(
      { __typename?: 'event' }
      & Pick<Event, 'type' | 'op' | 'data' | 'createdAt'>
    )> }
  )> }
);


export const DashboardDocument = gql`
    query dashboard {
  total: hostname_aggregate(where: {user: {billingKey: {_is_null: false}}}) {
    aggregate {
      count
    }
  }
  before: hostname_aggregate(
    where: {user: {billingKey: {_is_null: false}}, createdAt: {_lt: "2021-07-01"}}
  ) {
    aggregate {
      count
    }
  }
  after: hostname_aggregate(
    where: {user: {billingKey: {_is_null: false}}, createdAt: {_gte: "2021-07-01"}}
  ) {
    aggregate {
      count
    }
  }
  total_users: user_aggregate {
    aggregate {
      count
    }
  }
  paid_users: user_aggregate(where: {user_view: {hasBillingKey: {_eq: true}}}) {
    aggregate {
      count
    }
  }
  multiple_hostname_users: user_aggregate(
    where: {user_view: {_and: [{hasBillingKey: {_eq: true}}, {hostnamesCount: {_gt: 1}}]}}
  ) {
    aggregate {
      count
    }
  }
}
    `;
export type DashboardComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<DashboardQuery, DashboardQueryVariables>, 'query'>;

    export const DashboardComponent = (props: DashboardComponentProps) => (
      <ApolloReactComponents.Query<DashboardQuery, DashboardQueryVariables> query={DashboardDocument} {...props} />
    );
    
export function useDashboardQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DashboardQuery, DashboardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<DashboardQuery, DashboardQueryVariables>(DashboardDocument, options);
      }
export function useDashboardLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DashboardQuery, DashboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<DashboardQuery, DashboardQueryVariables>(DashboardDocument, options);
        }
export type DashboardQueryHookResult = ReturnType<typeof useDashboardQuery>;
export type DashboardLazyQueryHookResult = ReturnType<typeof useDashboardLazyQuery>;
export const HostnameDocument = gql`
    query hostname($where: hostname_bool_exp, $limit: Int, $offset: Int) {
  hostname(
    where: $where
    order_by: {createdAt: desc}
    limit: $limit
    offset: $offset
  ) {
    id
    name
    tags
  }
}
    `;
export type HostnameComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<HostnameQuery, HostnameQueryVariables>, 'query'>;

    export const HostnameComponent = (props: HostnameComponentProps) => (
      <ApolloReactComponents.Query<HostnameQuery, HostnameQueryVariables> query={HostnameDocument} {...props} />
    );
    
export function useHostnameQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<HostnameQuery, HostnameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<HostnameQuery, HostnameQueryVariables>(HostnameDocument, options);
      }
export function useHostnameLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<HostnameQuery, HostnameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<HostnameQuery, HostnameQueryVariables>(HostnameDocument, options);
        }
export type HostnameQueryHookResult = ReturnType<typeof useHostnameQuery>;
export type HostnameLazyQueryHookResult = ReturnType<typeof useHostnameLazyQuery>;
export const MembershipDataDocument = gql`
    query membershipData {
  hostname(
    where: {plan: {_eq: membership}, user: {email: {_nin: ["support@oopy.io", "sejong3408@gmail.com"]}}}
    order_by: {createdAt: asc}
  ) {
    id
    name
    createdAt
    members_aggregate {
      aggregate {
        count
      }
    }
    membershipConfiguration {
      defaultPermission
      signupTerms
      pg
    }
    membershipProducts {
      id
      name
      description
      permission
      membershipProductPrices(where: {type: {_neq: manual}}) {
        id
        name
        description
        currencyUnitAmount
        currency
        memberPayments {
          id
          amountReceived
        }
      }
    }
    user {
      id
      email
      nextPayTime
      user_view {
        hasBillingKey
      }
      payments {
        id
        price
        createdAt
        expiresAt
        paidAt
        poolSize
        membershipPoolSize
      }
    }
  }
}
    `;
export type MembershipDataComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<MembershipDataQuery, MembershipDataQueryVariables>, 'query'>;

    export const MembershipDataComponent = (props: MembershipDataComponentProps) => (
      <ApolloReactComponents.Query<MembershipDataQuery, MembershipDataQueryVariables> query={MembershipDataDocument} {...props} />
    );
    
export function useMembershipDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MembershipDataQuery, MembershipDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<MembershipDataQuery, MembershipDataQueryVariables>(MembershipDataDocument, options);
      }
export function useMembershipDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MembershipDataQuery, MembershipDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<MembershipDataQuery, MembershipDataQueryVariables>(MembershipDataDocument, options);
        }
export type MembershipDataQueryHookResult = ReturnType<typeof useMembershipDataQuery>;
export type MembershipDataLazyQueryHookResult = ReturnType<typeof useMembershipDataLazyQuery>;
export const NextPayBillDocument = gql`
    query nextPayBill($userId: String!) {
  nextPayBill(input: {userId: $userId}) {
    nextPayTotal
    currency
    discount
    nextPayTime
    vatIncluded
  }
}
    `;
export type NextPayBillComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<NextPayBillQuery, NextPayBillQueryVariables>, 'query'> & ({ variables: NextPayBillQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const NextPayBillComponent = (props: NextPayBillComponentProps) => (
      <ApolloReactComponents.Query<NextPayBillQuery, NextPayBillQueryVariables> query={NextPayBillDocument} {...props} />
    );
    
export function useNextPayBillQuery(baseOptions: ApolloReactHooks.QueryHookOptions<NextPayBillQuery, NextPayBillQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<NextPayBillQuery, NextPayBillQueryVariables>(NextPayBillDocument, options);
      }
export function useNextPayBillLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NextPayBillQuery, NextPayBillQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<NextPayBillQuery, NextPayBillQueryVariables>(NextPayBillDocument, options);
        }
export type NextPayBillQueryHookResult = ReturnType<typeof useNextPayBillQuery>;
export type NextPayBillLazyQueryHookResult = ReturnType<typeof useNextPayBillLazyQuery>;
export const UserDocument = gql`
    query user($where: user_bool_exp, $limit: Int, $offset: Int) {
  user(where: $where, order_by: {createdAt: desc}, limit: $limit, offset: $offset) {
    id
    email
    hostnames {
      name
      tags
      plan
    }
    createdAt
    referrerId
    user_view {
      hasPassword
      hasBillingKey
    }
    events {
      id
      type
      data
    }
  }
}
    `;
export type UserComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UserQuery, UserQueryVariables>, 'query'>;

    export const UserComponent = (props: UserComponentProps) => (
      <ApolloReactComponents.Query<UserQuery, UserQueryVariables> query={UserDocument} {...props} />
    );
    
export function useUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserQuery, UserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export const UserAggregationsDocument = gql`
    query userAggregations($createdAt: timestamp!) {
  total: user_aggregate(where: {createdAt: {_gte: $createdAt}}) {
    aggregate {
      count
    }
  }
  hasHostname: user_aggregate(
    where: {createdAt: {_gte: $createdAt}, user_view: {hostnamesCount: {_gt: 0}}}
  ) {
    aggregate {
      count
    }
  }
  hasBillingKey: user_aggregate(
    where: {createdAt: {_gte: $createdAt}, user_view: {hasBillingKey: {_eq: true}}}
  ) {
    aggregate {
      count
    }
  }
}
    `;
export type UserAggregationsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UserAggregationsQuery, UserAggregationsQueryVariables>, 'query'> & ({ variables: UserAggregationsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const UserAggregationsComponent = (props: UserAggregationsComponentProps) => (
      <ApolloReactComponents.Query<UserAggregationsQuery, UserAggregationsQueryVariables> query={UserAggregationsDocument} {...props} />
    );
    
export function useUserAggregationsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<UserAggregationsQuery, UserAggregationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<UserAggregationsQuery, UserAggregationsQueryVariables>(UserAggregationsDocument, options);
      }
export function useUserAggregationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserAggregationsQuery, UserAggregationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<UserAggregationsQuery, UserAggregationsQueryVariables>(UserAggregationsDocument, options);
        }
export type UserAggregationsQueryHookResult = ReturnType<typeof useUserAggregationsQuery>;
export type UserAggregationsLazyQueryHookResult = ReturnType<typeof useUserAggregationsLazyQuery>;
export const UserWithEventsDocument = gql`
    query userWithEvents($where: user_bool_exp, $offset: Int, $limit: Int) {
  user(where: $where, order_by: {createdAt: desc}, offset: $offset, limit: $limit) {
    id
    email
    hostnames {
      name
      tags
    }
    createdAt
    user_view {
      hasBillingKey
    }
    events(order_by: {createdAt: asc}) {
      type
      op
      data
      createdAt
    }
  }
}
    `;
export type UserWithEventsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UserWithEventsQuery, UserWithEventsQueryVariables>, 'query'>;

    export const UserWithEventsComponent = (props: UserWithEventsComponentProps) => (
      <ApolloReactComponents.Query<UserWithEventsQuery, UserWithEventsQueryVariables> query={UserWithEventsDocument} {...props} />
    );
    
export function useUserWithEventsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserWithEventsQuery, UserWithEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<UserWithEventsQuery, UserWithEventsQueryVariables>(UserWithEventsDocument, options);
      }
export function useUserWithEventsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserWithEventsQuery, UserWithEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<UserWithEventsQuery, UserWithEventsQueryVariables>(UserWithEventsDocument, options);
        }
export type UserWithEventsQueryHookResult = ReturnType<typeof useUserWithEventsQuery>;
export type UserWithEventsLazyQueryHookResult = ReturnType<typeof useUserWithEventsLazyQuery>;
export const UserWithEventsByIdDocument = gql`
    query userWithEventsById($id: uuid!) {
  user_by_pk(id: $id) {
    id
    email
    hostnames {
      name
    }
    createdAt
    user_view {
      hasBillingKey
    }
    events(order_by: {createdAt: asc}) {
      type
      op
      data
      createdAt
    }
  }
}
    `;
export type UserWithEventsByIdComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UserWithEventsByIdQuery, UserWithEventsByIdQueryVariables>, 'query'> & ({ variables: UserWithEventsByIdQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const UserWithEventsByIdComponent = (props: UserWithEventsByIdComponentProps) => (
      <ApolloReactComponents.Query<UserWithEventsByIdQuery, UserWithEventsByIdQueryVariables> query={UserWithEventsByIdDocument} {...props} />
    );
    
export function useUserWithEventsByIdQuery(baseOptions: ApolloReactHooks.QueryHookOptions<UserWithEventsByIdQuery, UserWithEventsByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<UserWithEventsByIdQuery, UserWithEventsByIdQueryVariables>(UserWithEventsByIdDocument, options);
      }
export function useUserWithEventsByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserWithEventsByIdQuery, UserWithEventsByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<UserWithEventsByIdQuery, UserWithEventsByIdQueryVariables>(UserWithEventsByIdDocument, options);
        }
export type UserWithEventsByIdQueryHookResult = ReturnType<typeof useUserWithEventsByIdQuery>;
export type UserWithEventsByIdLazyQueryHookResult = ReturnType<typeof useUserWithEventsByIdLazyQuery>;