import styled from "@emotion/styled";
import { UserEventsCard } from "components/UserEventsCard";
import {
  Enum_Event_Op_Enum,
  Enum_Event_Type_Enum,
  useUserAggregationsQuery,
  useUserWithEventsLazyQuery,
} from "graphql/generated";
import _ from "lodash";
import { useCallback } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useMemo } from "react";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
`;

const Header = styled.div`
  padding: 16px;
`;

const HeaderItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 280px;
`;

const HeaderLabel = styled.span`
  font-size: 16px;
`;

const HeaderValue = styled.span`
  font-size: 24px;
`;

const HeaderSubValue = styled.span`
  font-size: 16px;
`;

const ListContainer = styled.div`
  overflow-y: auto;
`;

export const UserEventsPage = () => {
  const _2weeksAgo = useMemo(
    () => new Date(Date.now() - 2 * 7 * 24 * 60 * 60 * 1000).toDateString(),
    []
  );

  const { data: aggregationData } = useUserAggregationsQuery({
    variables: {
      createdAt: _2weeksAgo,
    },
  });

  const [users, setUsers] = useState<UserWithEventsQuery["user"]>([]);

  const [query] = useUserWithEventsLazyQuery({
    variables: {
      where: {
        createdAt: {
          _gte: _2weeksAgo,
        },
      },
      limit: 10,
    },
    onCompleted: ({ user }) => {
      console.log(user);
      setUsers(($users) => _.uniqBy([...$users, ...user], "id"));
    },
  });

  const fetch = useCallback(
    (offset: number) => {
      query({
        variables: {
          where: {
            createdAt: {
              _gte: _2weeksAgo,
            },
          },
          limit: 10,
          offset,
        },
      });
    },
    [_2weeksAgo, query]
  );

  const count = useMemo(() => aggregationData?.total.aggregate?.count ?? 0, [
    aggregationData,
  ]);
  const hasHostname = useMemo(
    () => aggregationData?.hasHostname.aggregate?.count ?? 0,
    [aggregationData]
  );
  const hasBillingKey = useMemo(
    () => aggregationData?.hasBillingKey.aggregate?.count ?? 0,
    [aggregationData]
  );

  useEffect(() => {
    fetch(0);
  }, [fetch]);

  useEffect(() => {
    const onScroll = () => {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        fetch(users.length);
      }
    };
    document.addEventListener("scroll", onScroll);
    return () => document.removeEventListener("scroll", onScroll);
  }, [fetch, users]);

  useEffect(() => {
    const hasBillingKeyUsers = users.filter((user) =>
      user.events.find(
        (event) =>
          event.type === Enum_Event_Type_Enum.BillingKey &&
          event.op === Enum_Event_Op_Enum.Insert
      )
    );
    const timediff = hasBillingKeyUsers.map((user) => {
      const userInsertEvent = user.events.find(
        (event) => event.type === Enum_Event_Type_Enum.User
      );
      const billingKeyInsertEvent = user.events.find(
        (event) =>
          event.type === Enum_Event_Type_Enum.BillingKey &&
          event.op === Enum_Event_Op_Enum.Insert
      );
      return Math.floor(
        (new Date(billingKeyInsertEvent?.createdAt).valueOf() -
          new Date(userInsertEvent?.createdAt).valueOf()) /
          1000 /
          60
      );
    });

    console.log(timediff);
  }, [users]);

  useEffect(() => {
    const hasHostname = users.filter((user) =>
      user.events.find(
        (event) =>
          event.type === Enum_Event_Type_Enum.Hostname &&
          event.op === Enum_Event_Op_Enum.Insert
      )
    );
    const timediff = hasHostname.map((user) => {
      const userInsertEvent = user.events.find(
        (event) => event.type === Enum_Event_Type_Enum.User
      );
      const hostnameInsertEvent = user.events.find(
        (event) =>
          event.type === Enum_Event_Type_Enum.Hostname &&
          event.op === Enum_Event_Op_Enum.Insert
      );
      return Math.floor(
        (new Date(hostnameInsertEvent?.createdAt).valueOf() -
          new Date(userInsertEvent?.createdAt).valueOf()) /
          1000 /
          60
      );
    });

    console.log("hostname", timediff);
  }, [users]);

  return (
    <Container>
      <Header>
        <HeaderItem>
          <HeaderLabel>Total</HeaderLabel>
          <HeaderValue>{count}</HeaderValue>
        </HeaderItem>
        <HeaderItem>
          <HeaderLabel>Has Hostname</HeaderLabel>
          <HeaderValue>
            {hasHostname}
            <HeaderSubValue>
              ({((hasHostname / count) * 100).toFixed(2) + "%"})
            </HeaderSubValue>
          </HeaderValue>
        </HeaderItem>
        <HeaderItem>
          <HeaderLabel>Has BillingKey</HeaderLabel>
          <HeaderValue>
            {hasBillingKey}
            <HeaderSubValue>
              ({((hasBillingKey / count) * 100).toFixed(2) + "%"})
            </HeaderSubValue>
          </HeaderValue>
        </HeaderItem>
      </Header>
      <ListContainer>
        {users.map((user) => (
          <div style={{ marginBottom: 32 }}>
            <UserEventsCard key={user.id} user={user} />
          </div>
        ))}
      </ListContainer>
    </Container>
  );
};
