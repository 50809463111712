import styled from "@emotion/styled";
import { PagePicker } from "components/PagePicker";
import { Table } from "components/Table";
import { UserEventsCard } from "components/UserEventsCard";
import {
  Enum_Event_Type_Enum,
  Enum_Hostname_Plan_Enum,
  useUserQuery,
  useUserWithEventsByIdLazyQuery,
} from "graphql/generated";
import { usePagination } from "hooks/usePagination";

const Container = styled.div`
  display: flex;
  padding: 0 16px;
`;

const IndexPage = () => {
  const { page, offset, limit, setPage } = usePagination();

  const { data, loading, previousData } = useUserQuery({
    variables: {
      limit,
      offset,
    },
  });

  const [
    userWithEventsById,
    { data: eventsData },
  ] = useUserWithEventsByIdLazyQuery();

  return (
    <Container>
      <div>
        <h1>Users</h1>
        <Table style={{ marginBottom: 16 }}>
          <tr>
            <th>이메일</th>
            <th>홈페이지 주소</th>
            <th>비밀번호 등록</th>
            <th>카드 등록 시작</th>
            <th>카드 등록</th>
            <th>추천인 입력</th>
            <th>가입날짜</th>
          </tr>
          {(loading ? previousData : data)?.user.map((user) => (
            <>
              <tr
                onClick={() =>
                  userWithEventsById({
                    variables: {
                      id: user.id,
                    },
                  })
                }
              >
                <td>{user.email}</td>
                <td>
                  {user.hostnames.map(({ name, tags, plan }) => (
                    <a
                      style={{ textAlign: "center", display: "block" }}
                      href={`https://${name}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {name + (tags ? `(${tags})` : "")}{" "}
                      {plan === Enum_Hostname_Plan_Enum.Pro && (
                        <span style={{ fontWeight: 700, color: "purple" }}>
                          ({plan})
                        </span>
                      )}
                    </a>
                  ))}
                </td>
                <td>{user.user_view?.hasPassword ? "O" : "-"}</td>
                <td>
                  {user.events.find(
                    ({ type, data }) =>
                      type === Enum_Event_Type_Enum.Custom &&
                      data.value === "click-register-card"
                  )
                    ? "O"
                    : "-"}
                </td>
                <td>{user.user_view?.hasBillingKey ? "O" : "-"}</td>
                <td>{user.referrerId ? "O" : "-"}</td>
                <td style={{ textAlign: "center" }}>
                  {new Date(user.createdAt + "Z").toLocaleString()}
                </td>
              </tr>
            </>
          ))}
        </Table>
        <PagePicker
          style={{ alignSelf: "center" }}
          value={page}
          windowSize={10}
          maxWindowValue={Infinity}
          onChange={setPage}
        />
      </div>
      <div style={{ overflow: "auto" }}>
        {eventsData?.user_by_pk && (
          <UserEventsCard user={eventsData.user_by_pk} />
        )}
      </div>
    </Container>
  );
};

export default IndexPage;
