import styled from "@emotion/styled";
import { useDashboardQuery } from "graphql/generated";
import { useMemo } from "react";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  flex: 1;
  background-color: #f0f0f0;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 380px;
  background-color: #fff;
  box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.05);
  padding: 16px 16px 16px;
  border-radius: 16px;
`;

const CardTitle = styled.span`
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 16px;
`;

const CardContent = styled.span`
  font-size: 16px;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`;

export const DashboardPage = () => {
  const { data } = useDashboardQuery();

  const total = useMemo(() => data?.total?.aggregate?.count ?? 0, [data]);
  const before = useMemo(() => data?.before?.aggregate?.count ?? 0, [data]);
  const after = useMemo(() => data?.after?.aggregate?.count ?? 0, [data]);
  const paidUsers = useMemo(() => data?.paid_users?.aggregate?.count ?? 0, [
    data,
  ]);

  const multipleHostnameUsers = useMemo(
    () => data?.multiple_hostname_users?.aggregate?.count ?? 0,
    [data]
  );

  return (
    <Container>
      <h1>대시보드</h1>
      <Card>
        <CardTitle>비용 지불하는 홈페이지</CardTitle>
        <Row>
          <label>총</label>
          <CardContent>{total}</CardContent>
        </Row>
        <Row>
          <label>2021-07-01 이전 (3,900원)</label>
          <CardContent>
            {before}({((before / total) * 100).toFixed(0)}%)
          </CardContent>
        </Row>
        <Row>
          <label>2021-07-01 이후 (5,900원)</label>
          <CardContent>
            {after}({((after / total) * 100).toFixed(0)}%)
          </CardContent>
        </Row>
      </Card>
      <Card>
        <CardTitle>카드 등록 유저</CardTitle>
        <Row>
          <label>총 유저</label>
          <CardContent>{paidUsers}</CardContent>
        </Row>
        <Row>
          <label>2개 이상 호스트네임 유저 수</label>
          <CardContent>
            {multipleHostnameUsers}(
            {((multipleHostnameUsers / paidUsers) * 100).toFixed(0)}%)
          </CardContent>
        </Row>
      </Card>
    </Container>
  );
};
